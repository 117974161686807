import React from 'react';
import { styled } from '@glitz/react';
import { TransitionMotion, spring, OpaqueConfig } from 'react-motion';
import Spinner from 'Shared/Icon/Spinner';
import * as style from 'Shared/Style';

type PlainStyleType<TValue> = {
  opacity: TValue;
};

type StylesType<TValue> = {
  key: string;
  style: PlainStyleType<TValue>;
};

type PropType = {
  visible: boolean;
};

export default class Loader extends React.Component<PropType> {
  shouldComponentUpdate(nextProps: PropType) {
    const shouldUpdate = this.props.visible !== nextProps.visible;
    if (!shouldUpdate) {
      console.info('Ignoring update to Loader');
    }
    return shouldUpdate;
  }
  getStyles = (): Array<StylesType<OpaqueConfig>> =>
    this.props.visible
      ? [
          {
            key: 'spinner',
            style: { opacity: spring(1) },
          },
        ]
      : [];
  willEnter = (): PlainStyleType<number> => ({ opacity: 0 });
  willLeave = (): PlainStyleType<OpaqueConfig> => ({ opacity: spring(0) });
  render() {
    return (
      <TransitionMotion styles={this.getStyles} willEnter={this.willEnter} willLeave={this.willLeave}>
        {(interpolatedStyles: Array<StylesType<number>>) => {
          if (interpolatedStyles[0]) {
            const entry = interpolatedStyles[0];
            return (
              <Base key={entry.key} style={{ opacity: entry.style.opacity }}>
                <Glyph />
              </Base>
            );
          }

          return null;
        }}
      </TransitionMotion>
    );
  }
}

const Base = styled.div({
  position: 'fixed',
  zIndex: style.ZIndex.Spinner,
  top: '25vh',
  left: '50%',
  padding: { x: style.medium, y: style.medium },
  lineHeight: 1,
  fontSize: '1.5rem',
  color: style.primaryText,
  transform: 'translate(-50%, -50%)',
  pointerEvents: 'none',
  borderRadius: '50%',
  backgroundColor: style.primary,
});

const Glyph = styled(Spinner, {
  display: 'block',
});
