import { Breakpoint } from '@avensia/scope';
import { Style } from '@glitz/type';
import { media } from '@glitz/core';
import { tiny, small, medium, large, huge, gigantic } from './internal';
import { mediaMinQueries } from './media';

export enum Margin {
  Micro,
  Tiny,
  Small,
  Medium,
  Large,
}

const breakpoints = [Breakpoint.Micro, Breakpoint.Tiny, Breakpoint.Small, Breakpoint.Medium, Breakpoint.Large];

function margin(min: number, max: number, fraction: number) {
  return Number((min + (max - min) * fraction).toFixed(3));
}

const cache: { [fraction: number]: { [margin: number]: number } } = {};

const margins = (fraction: number) => {
  // Lets cache the calculated values even though they are cheap
  const values = (cache[fraction] = cache[fraction] || {
    [Margin.Micro]: margin(tiny, small, fraction),
    [Margin.Tiny]: margin(small, medium, fraction),
    [Margin.Small]: margin(medium, large, fraction),
    [Margin.Medium]: margin(large, huge, fraction),
    [Margin.Large]: margin(huge, gigantic, fraction),
  });

  return (margin: Margin) => values[margin];
};

type MarginMapperType = (margins: (margin: Margin) => number, breakpoint?: Breakpoint) => Style;

export function responsiveMargin(styles: MarginMapperType, initial = true): Style {
  const rules: Style[] = [];
  for (const breakpoint of breakpoints) {
    const style = styles(margins((breakpoints.indexOf(breakpoint) + 1) / breakpoints.length), breakpoint);
    if (typeof style === 'object') {
      rules.push(media(mediaMinQueries[breakpoint], style));
    }
  }

  return Object.assign(initial ? styles(margins(Breakpoint.Init), Breakpoint.Init) || {} : {}, ...rules);
}
