import React from 'react';
import { styled, StyledProps, StyledComponent } from '@glitz/react';
import Button, { PropType as ButtonPropType, Appearance as ButtonAppearance } from '../Button';
import Spinner from '../Icon/Spinner';
import FulfilledIcon from '../Icon/Check';
import RejectedIcon from '../Icon/Error';
import timeout from '../timeout';
import appearanceFunc from '../appearance';
import * as style from '../Style';

export { Variant, Appearance } from '../Button';

enum Status {
  Default,
  Pending,
  Fulfilled,
  Rejected,
}

export enum Behavior {
  /** Only clickable while idle, will never leave fulfilled state once reached. Default */
  SingleSuccess,
  /** Only clickable while idle or fulfilled. Will revert back from fulfilled to idle */
  MultipleSuccesses,
  /** Always clickable. Will revert back from fulfilled to idle. */
  KeepEnabled,
}

type OptionType = {
  minimumPending?: number;
  maximumFulfilled?: number;
  maximumRejected?: number;
  behavior?: Behavior;
};

type FeedbackFuncType = (asyncOperation: Promise<string | void>) => any;

type FeedbackType = {
  status: Status;
  text: string | void;
};

export type ConnectFeedbackButtonType = StyledComponent<FeedbackButtonPropType>;

export type ConnectPropType = {
  feedback: {
    push: FeedbackFuncType;
    Button: StyledComponent<FeedbackButtonPropType>;
  };
};

type LayerPropType = {
  visible: boolean;
};

type FeedbackButtonPropType = ButtonPropType;

type FeedbackSlavePropType = StyledProps &
  FeedbackButtonPropType & {
    feedback: FeedbackType;
  };

type ContextType = {
  status: Status;
  text: string | void;
  enabled: boolean;
};

type FeedbackButtonComponentPropType = Pick<
  FeedbackButtonPropType,
  Exclude<keyof FeedbackButtonPropType, 'onClick'>
> & {
  onClick: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => Promise<any>;
};

export default styled(
  connectWithFeedback()(
    class FeedbackButton extends React.Component<StyledProps & FeedbackButtonComponentPropType & ConnectPropType> {
      onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        if (this.props.onClick) {
          this.props.feedback.push(this.props.onClick(e));
        }
      };
      render() {
        const { feedback, compose, ...restProps } = this.props;
        return <feedback.Button {...restProps} onClick={this.onClick} css={compose()} />;
      }
    },
  ),
);

export function connectWithFeedback(options: OptionType = {}) {
  return <TInnerProps extends ConnectPropType>(Component: React.ComponentType<TInnerProps>) => {
    const {
      minimumPending = 10000,
      maximumFulfilled = options.behavior === Behavior.SingleSuccess ? Infinity : 2000,
      maximumRejected = 2000,
      behavior = Behavior.MultipleSuccesses,
    } = options;

    const defaultFeedbackStatus: ContextType = {
      status: Status.Default,
      text: null,
      enabled: true,
    };

    const { Provider, Consumer } = React.createContext(defaultFeedbackStatus);

    const FeedbackSlave = styled(
      ({ compose, feedback, appearance, disabled, children, ...restProps }: FeedbackSlavePropType) => (
        <Consumer>
          {({ status, text, enabled }) => {
            const isDefault = status === Status.Default;
            const isPending = status === Status.Pending;
            const isFulFilled = status === Status.Fulfilled;
            const isRejected = status === Status.Rejected;
            const isBare = appearanceFunc(appearance)(ButtonAppearance.Bare);
            const statusAppearance = isRejected && !isBare ? ButtonAppearance.Primary : null;

            return (
              <Button
                css={compose({
                  position: 'relative',
                })}
                {...restProps}
                disabled={!enabled || disabled}
                appearance={typeof statusAppearance === 'number' ? [].concat(appearance, statusAppearance) : appearance}
              >
                <Text visible={isDefault}>{children}</Text>
                {!isDefault && (
                  <Layer visible={isFulFilled || isRejected} css={{ marginTop: '8px' }}>
                    {isFulFilled && (typeof text === 'string' ? text : <FulfilledIcon />)}
                    {isRejected && (typeof text === 'string' ? text : <RejectedIcon />)}
                  </Layer>
                )}
                {isPending && (
                  <Layer visible={isPending} css={{ marginTop: '8px' }}>
                    {<Spinner />}
                  </Layer>
                )}
              </Button>
            );
          }}
        </Consumer>
      ),
    );

    return class FeedbackConnector extends React.Component<
      Pick<TInnerProps, Exclude<keyof TInnerProps, keyof ConnectPropType>>,
      ContextType
    > {
      mounted: boolean;
      asyncOperationQueue: Promise<any> = Promise.resolve();
      state = defaultFeedbackStatus;
      componentDidMount() {
        this.mounted = true;
      }
      componentWillUnmount() {
        this.mounted = false;
      }
      push = async (asyncOperation: Promise<string | void>) => {
        const queue = (this.asyncOperationQueue = Promise.all([asyncOperation, this.asyncOperationQueue]));
        const isLastOperation = () => queue === this.asyncOperationQueue;

        if (this.mounted) {
          if (this.state.status !== Status.Pending) {
            this.setState({
              status: Status.Pending,
              enabled: behavior === Behavior.KeepEnabled,
            });
          }

          const setStatus = async (statusState: ContextType, resetState: ContextType, maximumDisplayed: number) => {
            if (maximumDisplayed > 0 && this.mounted) {
              this.setState(statusState);
            }

            if (maximumDisplayed < Infinity) {
              await timeout(maximumDisplayed);

              if (isLastOperation() && this.mounted) {
                this.setState(resetState);
              }
            }
          };

          // Sometimes you want the spinner to be visible so the user has a chance
          // to notice that something happen. Studies shows that users today expects
          // that things like this take some time. So if it's to quick they
          // assume that something went wrong. I know... stupid... right?
          const minimumPendingTimer = timeout(minimumPending);

          try {
            const [[text]] = [await queue, await minimumPendingTimer];
            if (isLastOperation()) {
              const enabled = behavior !== Behavior.SingleSuccess;
              await setStatus(
                { status: Status.Fulfilled, text, enabled },
                { ...defaultFeedbackStatus, enabled },
                maximumFulfilled,
              );
            }
          } catch (text) {
            if (isLastOperation()) {
              await setStatus({ status: Status.Rejected, text, enabled: true }, defaultFeedbackStatus, maximumRejected);
            }
          }
        }

        if (isLastOperation()) {
          this.asyncOperationQueue = Promise.resolve();
        }

        return Promise.resolve();
      };
      // tslint:disable-next-line member-ordering
      feedback = { push: this.push, Button: FeedbackSlave };
      render() {
        return (
          <Provider value={this.state}>
            <Component {...this.props} feedback={this.feedback} />
          </Provider>
        );
      }
    };
  };
}

const Text = styled(({ compose, visible, ...restProps }: StyledProps & LayerPropType) => (
  <styled.Span
    {...restProps}
    css={compose({
      display: 'block',
      ...style.truncate(),
      // There's a bug in Chrome that doesn't change the appearance of text color when
      // the browsers is performance optimizing the element and is completely hidden, that's
      // why the value is set to `0.01`
      opacity: visible ? 1 : 0.01,
      transform: visible ? 'scale(1)' : 'scale(0.01)',
      ...style.transition({ property: ['opacity', 'transform'] }),
    })}
  />
));

const Layer = styled(Text, {
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  textAlign: 'center',
  paddingTop: 'inherit',
  paddingBottom: 'inherit',
});
