import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import FacebookIcon from 'Shared/Icon/Facebook';
import TwitterIcon from 'Shared/Icon/Twitter';
import InstagramIcon from 'Shared/Icon/Instagram';
import YouTubeIcon from 'Shared/Icon/YouTube';
import FooterType from './FooterViewModel.type';
import Viewport from 'Shared/Viewport';
import * as style from 'Shared/Style';
import { epiPropertyValue } from '@avensia/scope';

const SocialLinks = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const SocialLink = styled(Link, {
  fontSize: style.gamma,
  marginRight: style.small,
  color: style.primaryText,
});

type PropType = {
  footer: FooterType;
};

export default (props: PropType) => {
  return (
    <Viewport>
      {() => (
        <SocialLinks>
          {props.footer.block.instagramUrl && (
            <SocialLink
              key={2}
              to={epiPropertyValue(props.footer.block.instagramUrl)}
              css={{
                ...{ fontSize: '22px' },
              }}
            >
              <InstagramIcon />
            </SocialLink>
          )}
          {props.footer.block.facebookUrl && (
            <SocialLink
              key={2}
              to={epiPropertyValue(props.footer.block.facebookUrl)}
              css={{
                ...{ fontSize: '21px' },
              }}
            >
              <FacebookIcon />
            </SocialLink>
          )}
          {props.footer.block.twitterUrl && (
            <SocialLink
              key={1}
              to={epiPropertyValue(props.footer.block.twitterUrl)}
              css={{
                ...{ fontSize: '22px' },
              }}
            >
              <TwitterIcon />
            </SocialLink>
          )}
          {props.footer.block.youTubeUrl && (
            <SocialLink
              key={2}
              to={epiPropertyValue(props.footer.block.youTubeUrl)}
              css={{
                ...{
                  fontSize: '26px',
                  marginLeft: '5px',
                  marginTop: '-2px',
                },
              }}
            >
              <YouTubeIcon />
            </SocialLink>
          )}
        </SocialLinks>
      )}
    </Viewport>
  );
};
