import React from 'react';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import { ContentArea } from '@avensia/scope';
import { ACCOUNT_MODE } from './action-creators';

type ConnectStateType = {
  loginBlock: Scope.ContentArea;
  registerBlock: Scope.ContentArea;
  forgotPasswordBlock: Scope.ContentArea;
  activeAccountMode: ACCOUNT_MODE;
};

type PropType = {} & ConnectStateType;

const Base = styled.div({});

export default connect(
  (state): ConnectStateType => ({
    loginBlock: state.appShellData.loginBlock,
    registerBlock: state.appShellData.registerBlock,
    forgotPasswordBlock: state.appShellData.forgotPasswordBlock,
    activeAccountMode: state.currentUser.activeAccountMode,
  }),
)(function AccountPanel(props: PropType) {
  const ActivePanel = {
    [ACCOUNT_MODE.Login]: <ContentArea {...props.loginBlock} />,
    [ACCOUNT_MODE.Registration]: <ContentArea {...props.registerBlock} />,
    [ACCOUNT_MODE.ForgotPassword]: <ContentArea {...props.forgotPasswordBlock} />,
  }[props.activeAccountMode];
  return <Base>{ActivePanel}</Base>;
} as React.SFC<PropType>);
