import React from 'react';
import Item, { Label, LinkStyling } from './Item';
import { itemKey } from '../MainMenu/item-helpers';
import { Ul } from 'Shared/Generic';
import MainMenuItemType from '../MainMenu/MainMenuItem.type';
import { StyledProps, styled } from '@glitz/react';
import * as style from 'Shared/Style';
import connect from 'Shared/connect';
import { openAccountPanel, ACCOUNT_MODE, setSnackMessage } from 'Account/action-creators';
import { post, updateAppShellData } from '@avensia/scope';
import { translate } from '@avensia/scope';

export function isRoot(data: MainMenuItemType[] | MainMenuItemType): data is MainMenuItemType[] {
  return Array.isArray(data);
}
type DispatchPropType = {
  openAccountPanel: (mode: ACCOUNT_MODE) => void;
  updateAppShellData: () => Promise<any>;
  setSnackMessage: (snackMessage: string) => void;
};

type PropType = DispatchPropType & {
  isLoggedIn: boolean;
  primary: MainMenuItemType[] | MainMenuItemType;
  secondary: MainMenuItemType[];
  pushLevel: (item: MainMenuItemType) => void;
};

const LoginItem = styled.span(LinkStyling);

const LevelStyled = styled(
  class Level extends React.Component<PropType & StyledProps> {
    doLogout() {
      return post('/dologout')
        .then(() => {
          return this.props.updateAppShellData();
        })
        .then(() => {
          this.props.setSnackMessage(translate('/Account/Logout/Successful'));
        });
    }

    onLogoutClick = () => this.doLogout();

    login = () => {
      this.props.openAccountPanel(ACCOUNT_MODE.Login);
    };
    render() {
      const { primary, secondary } = this.props;
      const items = isRoot(primary) ? primary : primary.children || [];
      const secondaryItems = secondary;

      return (
        <React.Fragment>
          <Ul column css={this.props.compose()}>
            {items.map(item => (
              <li key={itemKey(item)}>
                <Item item={item} pushLevel={this.props.pushLevel} />
              </li>
            ))}
          </Ul>
          {secondary.length > 0 && (
            <React.Fragment>
              <Line />
              <Ul column css={this.props.compose()}>
                {secondaryItems.map(item => (
                  <li key={itemKey(item)}>
                    <Item item={item} pushLevel={this.props.pushLevel} />
                  </li>
                ))}
                {this.props.isLoggedIn ? (
                  <li style={{ cursor: 'pointer' }} onClick={this.onLogoutClick}>
                    <LoginItem>
                      <Label>{translate('/Account/Logout/Button')}</Label>
                    </LoginItem>
                  </li>
                ) : (
                  <li style={{ cursor: 'pointer' }} onClick={this.login}>
                    <LoginItem>
                      <Label>{translate('/Account/Login/Button')}</Label>
                    </LoginItem>
                  </li>
                )}
              </Ul>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  },
);

const Line = styled.div({
  width: '89%',
  borderTop: {
    color: style.GRAY,
    style: 'solid',
    width: 2,
  },
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '24px',
});

export default connect(
  state => ({
    isLoggedIn: state.currentUser ? state.currentUser.isLoggedIn : false,
  }),
  dispatch => ({
    openAccountPanel(mode: ACCOUNT_MODE) {
      return dispatch(openAccountPanel(mode));
    },
    updateAppShellData() {
      return dispatch(updateAppShellData());
    },
    setSnackMessage(snackMessage: string) {
      return dispatch(setSnackMessage(snackMessage));
    },
  }),
)(LevelStyled);
