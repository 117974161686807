/**
 * @ComponentFor FooterBlock
 */
import React from 'react';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import { shallowEquals } from '@avensia/scope';
import connect from 'Shared/connect';
import FooterType from './FooterViewModel.type';
import AvailableLanguageType from 'AppShell/AvailableLanguageViewModel.type';
import FooterBlock from './FooterBlock';
import * as style from 'Shared/Style';
import { Breakpoint } from '@avensia/scope';

type ConnectedStateType = {
  footer: FooterType;
  availableLanguages: AvailableLanguageType[];
};

type PropType = ConnectedStateType;

const Base = styled.div({
  position: 'absolute',
  width: '100%',
  padding: {
    top: '15px',
  },
  color: style.primaryText,
  backgroundColor: style.secondaryDarker,
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    padding: {
      top: '62px',
    },
  }),
});

class MainFooter extends React.Component<PropType> {
  shouldComponentUpdate(nextProps: PropType) {
    const shouldUpdate = !shallowEquals(this.props, nextProps, true);
    if (!shouldUpdate) {
      console.info('Ignoring update to MainFooter');
    }
    return shouldUpdate;
  }
  render() {
    const { footer } = this.props;
    return (
      <Base id="footer-container">
        <FooterBlock footer={footer} />
      </Base>
    );
  }
}
export default connect(
  (state): ConnectedStateType => ({
    footer: state.footer,
    availableLanguages: state.appShellData.availableLanguages,
  }),
)(MainFooter);
