import * as React from 'react';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import Hamburger from 'Shared/Icon/Menu';

type PropType = {
  open: boolean;
  openMainMenu: (e: React.MouseEvent<HTMLElement>) => void;
};

export default class QuickMenu extends React.Component<PropType> {
  render() {
    return (
      <Button appearance={[ButtonAppearance.Bare]} onClick={this.props.openMainMenu}>
        <Hamburger />
      </Button>
    );
  }
}
