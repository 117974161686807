import React from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@glitz/react';
import Overlay from 'Shared/Overlay';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import Close from 'Shared/Icon/Close';
import * as style from 'Shared/Style';
import Viewport from 'Shared/Viewport';
type PropType = React.HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose: () => void;
};
const Base = styled.div({
  position: 'fixed',
  width: '95%',
  maxWidth: '600px',
  left: '50%',
  top: '12%',
  backgroundColor: style.WHITE,
  transform: 'translateX(-50%)',
  padding: {
    x: style.medium,
    y: style.medium,
  },
  boxShadow: '0 0 5px 1px #979797',
});
const MobileBase = styled.div({
  position: 'fixed',
  width: '100%',
  height: '100%',
  left: '50%',
  backgroundColor: style.WHITE,
  transform: 'translateX(-50%)',
  padding: {
    x: style.medium,
    y: style.medium,
  },
  top: 0,
});
const InnerContainer = styled.div({
  position: 'relative',
});
const CloseButton = styled(Button, {
  width: '46px',
  position: 'absolute',
  right: 0,
  top: 0,
});
export default class Modal extends React.Component<PropType, {}> {
  rootElement: HTMLElement;
  constructor(props: PropType) {
    super(props);
  }
  componentDidMount() {
    // TODO: Replace with Context when upgraded to React 16.3 and remove content-container id from sitelayout.
    this.rootElement = document.getElementById('content-container');
  }
  render() {
    return this.rootElement
      ? ReactDOM.createPortal(
          <Overlay enabled={this.props.isOpen} onClose={this.props.onClose}>
            {this.props.isOpen && (
              <Viewport>
                {(isCompact: boolean) =>
                  isCompact ? (
                    <MobileBase>
                      <InnerContainer>
                        {this.props.children}
                        <CloseButton
                          appearance={ButtonAppearance.Bare}
                          variant={ButtonVariant.Small}
                          onClick={this.props.onClose}
                        >
                          <Close />
                        </CloseButton>
                      </InnerContainer>
                    </MobileBase>
                  ) : (
                    <Base>
                      <InnerContainer>
                        {this.props.children}
                        <CloseButton
                          appearance={ButtonAppearance.Bare}
                          variant={ButtonVariant.Small}
                          onClick={this.props.onClose}
                        >
                          <Close />
                        </CloseButton>
                      </InnerContainer>
                    </Base>
                  )
                }
              </Viewport>
            )}
          </Overlay>,
          document.getElementById('container'),
        )
      : null;
  }
}
