import { Breakpoint, widthFromBreakpoint } from '@avensia/scope';
import { query } from '@glitz/core';
import { pixelsToUnit } from './unit';

function mediaMinQuery(breakpoint: Breakpoint) {
  return query({
    minWidth: pixelsToUnit(widthFromBreakpoint(breakpoint)),
  });
}

function mediaMaxQuery(breakpoint: Breakpoint) {
  return query({
    maxWidth: pixelsToUnit(widthFromBreakpoint(breakpoint)),
  });
}

export function mediaBetweenQuery(breakpointMin: Breakpoint, breakpointMax: Breakpoint) {
  return query({
    minWidth: pixelsToUnit(widthFromBreakpoint(breakpointMin)),
    maxWidth: pixelsToUnit(widthFromBreakpoint(breakpointMax)),
  });
}

/** Media query for minimum window width for smaller smartphones in portrait mode */
export const mediaMinMicro = mediaMinQuery(Breakpoint.Micro);

/** Media query for minimum window width for larger smartphones and smaller tablets in portrait mode */
export const mediaMinTiny = mediaMinQuery(Breakpoint.Tiny);

/** Media query for minimum window width for larger tablets in portrait mode */
export const mediaMinSmall = mediaMinQuery(Breakpoint.Small);

/** Media query for minimum window width for smaller tablets in landscape mode */
export const mediaMinMedium = mediaMinQuery(Breakpoint.Medium);

/** Media query for minimum window width for larger tablets in landscape mode, smaller laptops and desktops */
export const mediaMinLarge = mediaMinQuery(Breakpoint.Large);

/** Media query for minimum window width for larger laptops and desktops */
export const mediaMinHuge = mediaMinQuery(Breakpoint.Huge);

export const mediaMinQueries = {
  [Breakpoint.Micro]: mediaMinMicro,
  [Breakpoint.Tiny]: mediaMinTiny,
  [Breakpoint.Small]: mediaMinSmall,
  [Breakpoint.Medium]: mediaMinMedium,
  [Breakpoint.Large]: mediaMinLarge,
  [Breakpoint.Huge]: mediaMinHuge,
};

/** Media query for minimum window width for smaller smartphones in portrait mode */
export const mediaMaxMicro = mediaMaxQuery(Breakpoint.Micro);

/** Media query for minimum window width for larger smartphones and smaller tablets in portrait mode */
export const mediaMaxTiny = mediaMaxQuery(Breakpoint.Tiny);

/** Media query for minimum window width for larger tablets in portrait mode */
export const mediaMaxSmall = mediaMaxQuery(Breakpoint.Small);

/** Media query for minimum window width for smaller tablets in landscape mode */
export const mediaMaxMedium = mediaMaxQuery(Breakpoint.Medium);

/** Media query for minimum window width for larger tablets in landscape mode, smaller laptops and desktops */
export const mediaMaxLarge = mediaMaxQuery(Breakpoint.Large);

/** Media query for minimum window width for larger laptops and desktops */
export const mediaMaxHuge = mediaMaxQuery(Breakpoint.Huge);

export const mediaMaxQueries = {
  [Breakpoint.Micro]: mediaMaxMicro,
  [Breakpoint.Tiny]: mediaMaxTiny,
  [Breakpoint.Small]: mediaMaxSmall,
  [Breakpoint.Medium]: mediaMaxMedium,
  [Breakpoint.Large]: mediaMaxLarge,
  [Breakpoint.Huge]: mediaMaxHuge,
};
