import { styled } from '@glitz/react';
import * as style from '../Style';

export default styled.hr({
  border: {
    xy: {
      width: 1,
      style: 'solid',
      color: style.monochromeLight,
    },
  },
});
