import { Style } from '@glitz/type';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import * as style from '../Style';

const reset: Style = {
  color: style.primary,
  textDecoration: 'underline',
};

const hover: Style = {
  ...reset,
  color: 'inherit',
};

export default styled(Link, {
  // "Lord Vader Hates Furry Animals"
  ...reset,
  ':link': reset,
  ':visited': reset,
  ':hover': hover,
  ':focus': hover,
  ':active': hover,
});
