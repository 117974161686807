import React from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@glitz/react';
import Overlay from 'Shared/Overlay';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import Close from 'Shared/Icon/Close';
import * as style from 'Shared/Style';
import { Checkbox } from 'Shared/Fields/Toggle';
import { EpiProperty, Breakpoint, isBrowser } from '@avensia/scope';
import FooterType from './FooterViewModel.type';
import { media } from '@glitz/core';
import HexagonButton from 'Shared/Button/Hexagon';

type PropType = React.HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  email: string;
  onSubmit: () => void;
  onClose: () => void;
  footer: FooterType;
  successful: boolean;
};

type StateType = {
  onSubscribeAccept: boolean;
  showTooltip: boolean;
};

const Base = styled.div({
  position: 'absolute',
  height: 'auto',
  bottom: '7px',
  left: '-7px',
  backgroundColor: style.WHITE,
  width: '100%',
  padding: {
    x: style.medium,
    y: style.medium,
  },
  boxShadow: '0 0 5px 1px #979797',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    maxWidth: '600px',
    minHeight: '100px',
  }),
});

const InnerContainer = styled.div({
  position: 'relative',
  paddingRight: '30px',
});

const CloseButton = styled(Button, {
  width: '46px',
  position: 'absolute',
  right: 0,
  top: '5px',
  color: style.BLACK,
});

const SubscribeAccept = styled(Checkbox, {});

const Span = styled.span({});

const AgreementInfo = styled.div({
  color: style.BLACK,
  width: '100%',
});

const CheckboxLabel = styled.label({
  fontSize: '16px',
  fontWeight: 300,
  marginLeft: '9px',
  marginBottom: 0,
  color: style.secondaryDarker,
});

const TooltipText = styled.span({
  position: 'absolute',
  zIndex: 1,
  background: style.primaryDark,
  color: style.WHITE,
  minWidth: '200px',
  textAlign: 'center',
  padding: {
    left: '5px',
    right: '5px',
  },
  bottom: '60px',
  right: '20px',
  borderRadius: '6px',
  ':after': {
    content: '"',
    position: 'absolute',
    top: '100%',
    right: '28%',
    marginLeft: '-8px',
    width: 0,
    height: 0,
    borderRadius: '6px',
    border: {
      top: {
        width: '8px',
        style: 'solid',
        color: style.primaryDark,
      },
      right: {
        width: '8px',
        style: 'solid',
        color: 'transparent',
      },
      left: {
        width: '8px',
        style: 'solid',
        color: 'transparent',
      },
    },
  },
});

const ButtonWrapper = styled.div({
  float: 'right',
});

const SubSuccessMessage = styled.div({
  color: style.secondaryDarker,
});

export default class NewsLetterModal extends React.Component<PropType, StateType, {}> {
  rootElement: HTMLElement;
  constructor(props: PropType) {
    super(props);
    this.state = {
      onSubscribeAccept: false,
      showTooltip: false,
    };
    if (isBrowser()) {
      this.rootElement = document.getElementById('footer-container');
    }
  }

  onSubscribeAccept = () => {
    this.setState({ onSubscribeAccept: !this.state.onSubscribeAccept });
  };

  componentWillReceiveProps(nextProps: PropType) {
    if (nextProps.successful === false) {
      this.setState({ onSubscribeAccept: false });
    }
  }

  handleMouseHover = () => {
    if (!this.state.onSubscribeAccept) {
      this.setState({
        showTooltip: !this.state.showTooltip,
      });
      setTimeout(() => {
        this.setState({
          showTooltip: !this.state.showTooltip,
        });
      }, 2000);
    }
  };

  render() {
    return this.rootElement
      ? ReactDOM.createPortal(
          <Overlay enabled={this.props.isOpen} onClose={this.props.onClose}>
            {this.props.isOpen && (
              <Base>
                {this.props.successful ? (
                  <>
                    <SubSuccessMessage>
                      <EpiProperty for={this.props.footer.block.newsletterSuccessText} component={Span} />
                    </SubSuccessMessage>
                    <ButtonWrapper>
                      <HexagonButton onClick={this.props.onClose} appearance={ButtonAppearance.Primary}>
                        Close
                      </HexagonButton>
                    </ButtonWrapper>
                  </>
                ) : (
                  <>
                    <InnerContainer>
                      <AgreementInfo>
                        <EpiProperty for={this.props.footer.block.newsletterAgreementInfo} component={Span} />
                      </AgreementInfo>
                      <styled.Div style={{ display: 'flex', flexDirection: 'row' }}>
                        <SubscribeAccept
                          type="checkbox"
                          name="signup"
                          checked={this.state.onSubscribeAccept}
                          onChange={e => this.setState({ onSubscribeAccept: (e.target as HTMLInputElement).checked })}
                          style={{ paddingBottom: '8px' }}
                        />
                        <CheckboxLabel style={{ float: 'right', width: '60%' }}>
                          <EpiProperty for={this.props.footer.block.newsletterAgreementLinkText} component={Span} />
                        </CheckboxLabel>
                      </styled.Div>
                    </InnerContainer>
                    <ButtonWrapper>
                      <HexagonButton
                        appearance={this.state.onSubscribeAccept ? ButtonAppearance.Primary : ButtonAppearance.Disabled}
                        onClick={this.props.onSubmit}
                        disabled={this.state.onSubscribeAccept ? false : true}
                        onMouseEnter={this.handleMouseHover}
                        fill={this.state.onSubscribeAccept ? style.primary : style.tertiaryDark}
                      >
                        Submit
                      </HexagonButton>
                    </ButtonWrapper>
                  </>
                )}
                {this.state.showTooltip && (
                  <TooltipText>
                    <EpiProperty for={this.props.footer.block.newsletterAgreementTooltip} component={Span} />
                  </TooltipText>
                )}
                <CloseButton
                  appearance={ButtonAppearance.Bare}
                  variant={ButtonVariant.Small}
                  onClick={this.props.onClose}
                >
                  <Close />
                </CloseButton>
              </Base>
            )}
          </Overlay>,
          this.rootElement,
        )
      : null;
  }
}
