import { SystemTextType, Action } from 'Shared/State';
import { SHOW_SYSTEM_TEXT, HIDE_SYSTEM_TEXT, ShowSystemTextAction } from './action-creator';

export default function systemTextReducer(state: SystemTextType = { text: null }, action: Action) {
  switch (action.type) {
    case SHOW_SYSTEM_TEXT: {
      const { text } = action as ShowSystemTextAction;
      return { text };
    }
    case HIDE_SYSTEM_TEXT: {
      return { text: null };
    }
  }
  return state;
}
