import constants from './constants';

const { BASE_FONT_SIZE, BASE_STYLE_UNIT } = constants;

export enum Unit {
  Px,
  Em,
  Rem,
}

let baseUnitNum: Unit;
switch (BASE_STYLE_UNIT) {
  case 'em':
    baseUnitNum = Unit.Em;
    break;
  case 'rem':
    baseUnitNum = Unit.Rem;
    break;
  default:
    baseUnitNum = Unit.Px;
}

export function pixelsToUnit(value: number, unit: Unit = baseUnitNum) {
  return unit === Unit.Px ? `${value}px` : `${value / BASE_FONT_SIZE}${unit === Unit.Rem ? 'rem' : 'em'}`;
}
