import React from 'react';
import { Style } from '@glitz/type';
import { styled } from '@glitz/react';
import timeout from '../timeout';
import * as style from '../Style';

const EFFECT_DURATION = 1000;

type RippleType = {
  time: number;
  top: number;
  left: number;
  radius: number;
};

type PropType = {
  children?: (
    ripples: Array<React.ReactElement<any>>,
    push: (e: React.MouseEvent<HTMLElement>) => Promise<void>,
  ) => React.ReactElement<any>;
};

type StateType = {
  ripples: RippleType[];
};

export default class RippleEffect extends React.Component<PropType, StateType> {
  mounted: boolean;
  el: HTMLDivElement;
  state: StateType = {
    ripples: [],
  };
  componentWillMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  push = async (e: React.MouseEvent<HTMLElement>) => {
    const time = Date.now();

    const element = e.currentTarget;
    const { clientHeight, clientWidth } = element;
    const rect = element.getBoundingClientRect();
    const scaleX = rect.width / clientWidth;
    const scaleY = rect.height / clientHeight;
    const radius = Math.max(clientWidth, clientHeight);

    const initialProps: RippleType = {
      time,
      radius: 0,
      top: ((e.clientY - rect.top) / scaleY - radius) / clientHeight,
      left: ((e.clientX - rect.left) / scaleX - radius) / clientWidth,
    };

    // Makes sure the node is mounted and hardware accelerated
    // by browser before we animate
    await new Promise(resolve => {
      this.setState({ ripples: [...this.state.ripples, initialProps] }, () => resolve());
    });

    const animateProps: RippleType = {
      ...initialProps,
      radius,
    };

    if (this.mounted) {
      await new Promise(resolve => {
        const ripples = [...this.state.ripples];
        const index = ripples.indexOf(initialProps);
        ripples.splice(index, 1, animateProps);
        this.setState({ ripples }, () => resolve());
      });

      await timeout(EFFECT_DURATION);
    }

    if (this.mounted) {
      const ripples = [...this.state.ripples];
      const index = ripples.indexOf(animateProps);
      ripples.splice(index, 1);
      this.setState({ ripples });
    }
  };
  render() {
    return this.props.children(
      this.state.ripples.map(({ time, top, left, radius }) => (
        <Ripple
          key={time}
          style={{
            top: `${top * 100}%`,
            left: `${left * 100}%`,
            width: `${radius * 2}px`,
            height: `${radius * 2}px`,
          }}
        />
      )),
      this.push,
    );
  }
}

export const rippleContainerStyle: Style = {
  position: 'relative',
  overflow: 'hidden',
};

const Ripple = styled.span({
  position: 'absolute',
  willChange: 'opacity, transform',
  borderRadius: '50%',
  backgroundColor: 'currentColor',
  pointerEvents: 'none',
  ...style.animation({
    name: {
      from: {
        opacity: 0.25,
        transform: 'scale(0)',
      },
      to: {
        opacity: 0,
        transform: 'scale(1)',
      },
    },
    duration: EFFECT_DURATION,
    fillMode: 'forwards',
  }),
});
