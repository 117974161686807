import React from 'react';
import { Motion, spring, OpaqueConfig } from 'react-motion';
import SiteBanner from 'Shared/SiteBanner';

type StyleType<PValue> = {
  height: PValue;
};

type PropType = {
  children: React.ReactNode;
};

type StateType = {
  height: number;
  children: React.ReactNode;
  enabled: boolean;
};

export default class SystemText extends React.Component<PropType, StateType> {
  public static getDerivedStateFromProps(props: PropType, state: StateType): Partial<StateType> | null {
    return {
      children: props.children || state.children,
      enabled: !!props.children,
    };
  }
  ref = React.createRef<HTMLDivElement>();
  constructor(props: PropType) {
    super(props);
    this.state = {
      height: 0,
      // Keep children in state so that it doesn't disappear when animating out
      children: props.children,
      enabled: !!props.children,
    };
  }
  componentDidUpdate() {
    this.updateHeight();
  }
  componentDidMount() {
    this.updateHeight();
  }
  updateHeight() {
    const el = this.ref.current;
    if (el) {
      const height = el.offsetHeight;
      if (this.state.height !== height) {
        this.setState({ height });
      }
    }
  }
  render() {
    const motionStyle: StyleType<OpaqueConfig | number> = {
      height: this.state.enabled ? spring(this.state.height) : spring(0),
    };
    return (
      <Motion style={motionStyle}>
        {({ height: interpolatedHeight }: Partial<StyleType<number>>) => (
          <SiteBanner style={{ height: interpolatedHeight }} elementRef={this.ref}>
            {this.state.children}
          </SiteBanner>
        )}
      </Motion>
    );
  }
}
