import { addUserLog } from '@avensia/scope';
import { Action } from 'Shared/State';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';

export type OpenMainMenuAction = Action & {
  menuItem: MainMenuItemType;
};

export const OPEN_MAIN_MENU = 'TOGGLE_MAIN_MENU';
export const CLOSE_MAIN_MENU = 'CLOSE_MAIN_MENU';
export const TOGGLE_QUICK_MENU = 'TOGGLE_QUICK_MENU';

export function openMainMenu(menuItem: MainMenuItemType) {
  addUserLog('Opening main menu');
  return {
    type: OPEN_MAIN_MENU,
    menuItem,
  } as Action;
}

export function closeMainMenu() {
  addUserLog('Closing main menu');
  return {
    type: CLOSE_MAIN_MENU,
  } as Action;
}

export function toggleQuickMenu() {
  addUserLog('Toggling quick menu');
  return {
    type: TOGGLE_QUICK_MENU,
  } as Action;
}
