import factory from './icon';
import Svg from './glyphs/menu-arrow-forward.svg';
import { styled } from '@glitz/react';

export const Right = factory(Svg);

export const Down = styled(Right, {
  transform: 'rotate(90deg)',
});
export const Left = styled(Right, {
  transform: 'rotate(180deg)',
});
export const Up = styled(Right, {
  transform: 'rotate(270deg)',
});
