import {
  PAGE_LOAD_FAILURE,
  UPDATE_APP_SHELL_DATA,
  PageLoadFailureAction,
  UpdateAppShellDataAction,
  PAGE_LOAD,
  translate,
} from '@avensia/scope';

import { SWITCH_BREAKPOINT } from '@avensia/scope/CurrentBreakpoint/action-creators';
import {
  OPEN_ACCOUNT_PANEL,
  CLOSE_ACCOUNT_PANEL,
  OPEN_SUBSCRIBE_PANEL,
  CLOSE_SUBSCRIBE_PANEL,
  SET_SNACK_MESSAGE,
  SET_ACTIVE_ACCOUNT_MODE,
  ACCOUNT_MODE,
  SetActiveAccountModeAction,
  SetSnackMessageAction,
  OpenAccountPanelAction,
  UpdateEmailAction,
  UPDATE_EMAIL,
  OPEN_CONFIRMATION_PANEL,
  CLOSE_CONFIRMATION_PANEL,
} from './action-creators';
import AppShellDataType from 'AppShell/AppShellData.type';
import { Action, CurrentUserType } from 'Shared/State';

export default function(
  state: CurrentUserType = null,
  action: Action,
  appShellData: AppShellDataType,
): CurrentUserType {
  state = state || {
    ...appShellData.currentUser,
    accountPanelIsOpen: false,
    subscribePanelIsOpen: false,
    activeAccountMode: ACCOUNT_MODE.Login,
    confirmationPanelIsOpen: false,
    hardReloadToAfterLogin: null,
    panelMessage: '',
  };
  switch (action.type) {
    case PAGE_LOAD_FAILURE:
      if ((action as PageLoadFailureAction).loadFailure.status === 401 && state.isLoggedIn) {
        // The user has either been logged out because of inactivity or the account has been
        // locked out
        return Object.assign({}, state, {
          isLoggedIn: false,
        });
      }

      if ((action as PageLoadFailureAction).loadFailure.status === 401) {
        return Object.assign({}, state, {
          accountPanelIsOpen: true,
          hardReloadToAfterLogin: (action as PageLoadFailureAction).url,
          panelMessage: translate('/Account/Login/LoginRequiredForThisPage'),
        });
      }

      return state;
    case OPEN_ACCOUNT_PANEL:
      return Object.assign({}, state, {
        accountPanelIsOpen: true,
        hardReloadToAfterLogin: (action as OpenAccountPanelAction).hardReloadToAfterLogin,
      });
    case OPEN_SUBSCRIBE_PANEL:
      return Object.assign({}, state, {
        subscribePanelIsOpen: true,
      });
    case SWITCH_BREAKPOINT:
      return Object.assign({}, state, {
        hardReloadToAfterLogin: null,
      });
    case CLOSE_ACCOUNT_PANEL:
      return Object.assign({}, state, {
        accountPanelIsOpen: false,
        hardReloadToAfterLogin: null,
      });
    case CLOSE_SUBSCRIBE_PANEL:
      return Object.assign({}, state, {
        subscribePanelIsOpen: false,
      });
    case OPEN_CONFIRMATION_PANEL:
      return Object.assign({}, state, {
        confirmationPanelIsOpen: true,
      });
    case CLOSE_CONFIRMATION_PANEL:
      return Object.assign({}, state, {
        confirmationPanelIsOpen: false,
      });
    case SET_SNACK_MESSAGE:
      return Object.assign({}, state, {
        snackMessage: (action as SetSnackMessageAction).snackMessage,
      });
    case SET_ACTIVE_ACCOUNT_MODE:
      return Object.assign({}, state, {
        activeAccountMode: (action as SetActiveAccountModeAction).mode.toString(),
      });
    case UPDATE_EMAIL:
      return Object.assign({}, state, {
        accountPanelEmail: (action as UpdateEmailAction).email,
      });
    case UPDATE_APP_SHELL_DATA:
      return Object.assign({}, state, (action as UpdateAppShellDataAction<AppShellDataType>).appShellData.currentUser);
    case PAGE_LOAD:
      return Object.assign({}, state, {
        snackMessage: null,
      });
    default:
      return state;
  }
}
