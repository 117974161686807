import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { isIOS, on, Breakpoint } from '@avensia/scope';
import { TransitionMotion, spring, OpaqueConfig } from 'react-motion';
import { object as PropTypeObject } from 'prop-types';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import * as style from 'Shared/Style';
import { ZIndex } from 'Shared/Style';
import connect from 'Shared/connect';

const TRANSLATE_X = 20;

type ItemPropType = {
  children?: React.ReactNode;
  color?: string;
};

type TrayStateType = {
  actionbar: {
    key: number;
    child: React.ReactNode;
  };
  snackbars: {
    [key: number]: {
      reactNode: React.ReactNode;
      color: string;
    };
  };
};

class TrayState {
  actionbarQueue = Promise.resolve();
  state: TrayStateType = {
    actionbar: null,
    snackbars: {},
  };
  updaters: Array<(state: Partial<TrayStateType>) => void> = [];
  update(state: Partial<TrayStateType>) {
    for (const key in state) {
      this.state[key] = state[key];
    }
    for (const updater of this.updaters) {
      updater(state);
    }
  }
  onUpdate(updater: (state: TrayStateType) => void) {
    this.updaters.push(updater);
    return () => {
      this.updaters.splice(this.updaters.indexOf(updater), 1);
    };
  }
  async setActionbar(child: React.ReactNode, key: number) {
    await this.actionbarQueue;
    this.update({
      actionbar: {
        key,
        child,
      },
    });
  }
  async pushRemoveActionbar() {
    await this.actionbarQueue;
    this.update({
      actionbar: null,
    });
  }
  removeActionbar(key: number) {
    if (this.state.actionbar && key === this.state.actionbar.key) {
      this.actionbarQueue = this.pushRemoveActionbar();
    }
  }
  updateSnackbar(child: React.ReactNode, key: number, color?: string) {
    this.update({
      snackbars: {
        ...this.state.snackbars,
        [key]: {
          reactNode: child,
          color,
        },
      },
    });
  }
  removeSnackbar(child: React.ReactNode, key: number) {
    const snackbars = { ...this.state.snackbars };
    delete snackbars[key];
    this.update({ snackbars });
  }
}

function snackbarKey(key: string) {
  return `s${key}`;
}

function actionbarKey(key: number) {
  return `a${key}`;
}

type ContextType = {
  tray: TrayState;
};

const contextTypes = {
  tray: PropTypeObject,
};

export class TrayProvider extends React.Component {
  static childContextTypes = contextTypes;
  childContext: ContextType = { tray: new TrayState() };
  getChildContext() {
    // Yes, we use context here and if this inspires you for another feature
    // please have a look here first: https://twitter.com/dan_abramov/status/749715530454622208
    return this.childContext;
  }
  render() {
    return React.Children.only(this.props.children);
  }
}

type PlainStyleType<TValue> = {
  opacity: TValue;
  translateY?: TValue;
  height?: TValue;
};

type StylesType<TValue> = {
  key: string;
  data: {
    isActionbar: boolean;
    child: {
      reactNode: React.ReactNode;
      color: string;
    };
  };
  style: PlainStyleType<TValue>;
};

type StateType = TrayStateType & {
  heights: { [key: number]: number };
  isFixed: boolean;
};

type PropType = StyledProps & ConnectStateType;

type ConnectStateType = {
  currentBreakpoint: number;
};

class TrayContainer extends React.Component<PropType, StateType> {
  static contextTypes = contextTypes;
  context: ContextType;
  unsubscribeUpdate: () => void;
  snackbarsElement: HTMLDivElement;
  ref: React.RefObject<HTMLDivElement>;
  unbindScroll: () => void;
  constructor(props: PropType, context: ContextType) {
    super(props, context);
    this.state = {
      heights: {},
      isFixed: false,
      ...context.tray.state,
    };
    this.unsubscribeUpdate = this.context.tray.onUpdate(trayState => {
      this.setState(trayState);
    });

    this.ref = React.createRef<HTMLDivElement>();
    this.handleSnackbarPosition = this.handleSnackbarPosition.bind(this);
  }

  componentDidMount() {
    this.unbindScroll = on('scroll', this.handleSnackbarPosition);
  }

  handleSnackbarPosition() {
    const element = this.ref.current;
    const scrollTop = document.documentElement.scrollTop || window.scrollY;
    const elementBottomPosition = element.offsetTop + element.offsetHeight;

    if (elementBottomPosition < scrollTop) {
      if (!this.state.isFixed) {
        this.setState({ isFixed: true });
      }
    }

    if (elementBottomPosition >= scrollTop) {
      if (this.state.isFixed) {
        this.setState({ isFixed: false });
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeUpdate();
    this.unbindScroll();
  }
  getStyles = (): Array<StylesType<OpaqueConfig>> => {
    return [
      ...(this.state.actionbar
        ? [
            {
              key: actionbarKey(this.state.actionbar.key),
              data: {
                isActionbar: true,
                child: {
                  reactNode: this.state.actionbar.child,
                },
              },
              style: { translateY: spring(0), opacity: spring(1) },
            } as StylesType<OpaqueConfig>,
          ]
        : []),
      ...Object.keys(this.state.snackbars)
        // Reversing to make sure new snackbars enters on top
        .reverse()
        .map(
          (key): StylesType<OpaqueConfig> => ({
            key: snackbarKey(key),
            data: {
              isActionbar: false,
              child: this.state.snackbars[Number(key)],
            },
            style: { opacity: spring(1), height: spring(this.state.heights[snackbarKey(key)] || 0) },
          }),
        ),
    ];
  };
  willEnter = (style: StylesType<OpaqueConfig>): PlainStyleType<number> => ({
    opacity: 0,
    ...(style.data.isActionbar ? { translateY: TRANSLATE_X } : { height: 0 }),
  });
  willLeave = (style: StylesType<OpaqueConfig>): PlainStyleType<OpaqueConfig> => ({
    opacity: spring(0),
    ...(style.data.isActionbar ? { translateY: spring(TRANSLATE_X) } : { height: spring(0) }),
  });
  motionStyleToCssProperties(style: PlainStyleType<number>): React.CSSProperties {
    return {
      opacity: style.opacity,
      transform: `translateY(${style.translateY}px)`,
    };
  }
  snackbarRef = (key: string) => (el: HTMLDivElement) => {
    if (el && el.offsetHeight !== this.state.heights[key]) {
      this.setState(state => ({ heights: { ...this.state.heights, [key]: el.offsetHeight } }));
    }
  };

  getPosition() {
    if (this.props.currentBreakpoint < Breakpoint.Medium) {
      return this.state.isFixed ? 0 : 65;
    }
    return this.state.isFixed ? 0 : 80;
  }

  render() {
    return (
      <styled.Div css={{ zIndex: ZIndex.Tray }} innerRef={this.ref}>
        <TransitionMotion styles={this.getStyles} willEnter={this.willEnter} willLeave={this.willLeave}>
          {(interpolatedStyles: Array<StylesType<number>>) => {
            const actionbars = interpolatedStyles.filter(entry => entry.data.isActionbar);
            const snackbars = interpolatedStyles.filter(entry => !entry.data.isActionbar);
            const top = this.getPosition();
            const position = this.state.isFixed ? 'fixed' : 'absolute';

            const styledGreen = style.GREEN;

            const SnackbarInner = styled(Basic, {
              color: style.BLACK,
              fontWeight: 500,
              fontSize: '20px',
              ...style.responsiveMargin(margins => ({
                padding: { y: margins(style.Margin.Tiny) },
              })),
            });

            return (
              (actionbars.length > 0 || snackbars.length > 0) && (
                <styled.Div style={{ position }} css={{ ...this.props.compose(), top, width: '100%' }}>
                  {actionbars.map(({ key, data, style }) => (
                    <ActionbarItem
                      key={key}
                      css={
                        // Since there is a bug in iOS below version 11, we need to add left 50%
                        // in order to center align the element
                        isIOS() && {
                          left: '50%',
                        }
                      }
                      style={{
                        opacity: style.opacity,
                        // Since there is a bug in iOS below version 11, we need to do translateX as well
                        // in order to center align the element
                        transform: `translateY(${style.translateY}px)${isIOS() ? ' translateX(-50%)' : ''}`,
                      }}
                    >
                      {data.child.reactNode}
                    </ActionbarItem>
                  ))}
                  {snackbars.length > 0 && (
                    <SnackbarList>
                      {snackbars.map(({ key, data, style }) => (
                        <styled.Div
                          key={key}
                          style={{
                            opacity: style.opacity,
                            height: style.height,
                            backgroundColor: data.child.color || styledGreen,
                            width: '100%',
                            overflow: 'hidden',
                            boxShadow: '0 0 5px 1px #979797',
                          }}
                        >
                          <SnackbarInner
                            appearance={[PageAppearance.Normal, PageAppearance.Gap]}
                            elementRef={this.snackbarRef(key)}
                            style={{
                              backgroundColor: data.child.color || styledGreen,
                            }}
                          >
                            {data.child.reactNode}
                          </SnackbarInner>
                        </styled.Div>
                      ))}
                    </SnackbarList>
                  )}
                </styled.Div>
              )
            );
          }}
        </TransitionMotion>
      </styled.Div>
    );
  }
}

export default connect<ConnectStateType>(state => ({
  currentBreakpoint: state.currentBreakpoint,
}))(TrayContainer);

export const Tray = styled(TrayContainer, {
  position: 'fixed',
  zIndex: style.ZIndex.Tray,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

const SnackbarList = styled.div({
  width: '100%',
});

const ActionbarItem = styled.div({
  position: 'absolute',
  // Negative z-index to animate it behind the snackbars
  zIndex: -1,
  bottom: '100%',
  display: 'flex',
});

function scrollReachedPageEnd() {
  return Math.ceil(window.innerHeight + window.pageYOffset) >= document.documentElement.offsetHeight;
}

let nextKey = 0;

export class Actionbar extends React.Component<ItemPropType> {
  static contextTypes = contextTypes;
  disabled: boolean = false;
  unsubscribeScroll: () => void;
  key: number;
  constructor(props: ItemPropType) {
    super(props);
    this.key = nextKey;
    nextKey++;
  }
  componentWillMount() {
    this.update(this.props.children);
  }
  componentDidMount() {
    this.unsubscribeScroll = on('scroll', this.togglePageEnd);
    this.togglePageEnd();
  }
  componentWillReceiveProps(nextProps: ItemPropType) {
    if (this.props.children !== nextProps.children && !this.disabled) {
      this.update(nextProps.children);
    }
  }
  componentWillUnmount() {
    this.unsubscribeScroll();
    if (!this.disabled) {
      this.remove();
    }
  }
  togglePageEnd = () => {
    const disabled = scrollReachedPageEnd();
    if (this.disabled !== disabled) {
      disabled ? this.remove() : this.update(this.props.children);
    }
    this.disabled = disabled;
  };
  update(children: React.ReactNode) {
    (this.context as ContextType).tray.setActionbar(children, this.key);
  }
  remove() {
    (this.context as ContextType).tray.removeActionbar(this.key);
  }
  render(): null {
    return null;
  }
}

export class Snackbar extends React.Component<ItemPropType> {
  static contextTypes = contextTypes;
  key: number;
  constructor(props: ItemPropType) {
    super(props);
    this.key = nextKey;
    nextKey++;
  }
  componentWillMount() {
    (this.context as ContextType).tray.updateSnackbar(this.props.children, this.key, this.props.color);
  }
  componentWillReceiveProps(nextProps: ItemPropType) {
    (this.context as ContextType).tray.updateSnackbar(nextProps.children, this.key, this.props.color);
  }
  componentWillUnmount() {
    (this.context as ContextType).tray.removeSnackbar(this.props.children, this.key);
  }
  render(): null {
    return null;
  }
}
