import React from 'react';
import { Store } from 'Shared/State';
import { Provider } from 'react-redux';
import SiteLayout from './index';

type Props = {
  store: Store;
};

export default class Container extends React.Component<Props> {
  render() {
    return (
      <Provider store={this.props.store}>
        <SiteLayout />
      </Provider>
    );
  }
}
