// Have properties order and preferred `z-index` order accordingly
// and make sure it always starts with 1
export enum ZIndex {
  Header = 1,
  Tray,
  BelowCover,
  Cover,
  Overlay,
  Spinner,
}
