import * as internal from './internal';

// Border
export const thin = internal.thin;
export const thick = internal.thick;

// Margin & padding
export const tiny = internal.tiny;
export const small = internal.small;
export const medium = internal.medium;
export const large = internal.large;
export const huge = internal.huge;
export const gigantic = internal.gigantic;
export const moderate = large; // This is default spacing, e.g. margin between `<p>`
