// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// scope.cmd frontend:build-component-registry
// instead.

/* tslint:disable */

// prettier-ignore
import { setComponentRegistry, setThemeContextCreatorRegistry } from '@avensia/scope/resolve-component';
// prettier-ignore
export { default as componentNames } from './component-names';

// prettier-ignore
const componentRegistry: {[name: string]: (theme: string) => Promise<{component: any, theme: string}>} = {
  'Avensia.Common.Features.Account.ForgotPassword.ForgotPasswordBlock': (theme: string) => import(/* webpackChunkName: "account" */ '../Account/ForgotPassword/ForgotPasswordBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.Login.LoginBlock': (theme: string) => import(/* webpackChunkName: "account" */ '../Account/Login/LoginBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.Register.RegisterBlock': (theme: string) => import(/* webpackChunkName: "account" */ '../Account/Register/RegisterBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.AppShell.Partials.AppShellViewModel': (theme: string) => import(/* webpackChunkName: "appshell" */ '../AppShell/Partials/AppShell').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Contact.ContactPageViewModel': (theme: string) => import(/* webpackChunkName: "contact" */ '../Contact/ContactPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Content.ContentPageViewModel': (theme: string) => import(/* webpackChunkName: "content" */ '../Content/ContentPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.EditorialContent.Events.EventListEditorialContentPageViewModel': (theme: string) => import(/* webpackChunkName: "editorialcontent" */ '../EditorialContent/EventList/EventListEditorialContentPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.EditorialContent.Events.EventPageViewModel': (theme: string) => import(/* webpackChunkName: "editorialcontent" */ '../EditorialContent/Events/EventPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.EditorialContent.News.NewsPageViewModel': (theme: string) => import(/* webpackChunkName: "editorialcontent" */ '../EditorialContent/News/NewsPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.EditorialContent.Events.NewsListEditorialContentPageViewModel': (theme: string) => import(/* webpackChunkName: "editorialcontent" */ '../EditorialContent/NewsList/NewsListEditorialContentPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel': (theme: string) => import(/* webpackChunkName: "error" */ '../Error/Pages/PageNotFound').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Marketing.MarketingPageViewModel': (theme: string) => import(/* webpackChunkName: "marketing" */ '../Marketing/MarketingPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel': (theme: string) => import(/* webpackChunkName: "resetpassword" */ '../ResetPassword/ResetPasswordPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Search.SearchPageResultViewModel': (theme: string) => import(/* webpackChunkName: "search" */ '../Search/SearchPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Attachment.AttachmentBlock': (theme: string) => import(/* webpackChunkName: "shared" */ './Blocks/Attachment/AttachmentBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Html.HtmlBlock': (theme: string) => import(/* webpackChunkName: "shared" */ './Blocks/Html/HtmlBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel': (theme: string) => import(/* webpackChunkName: "shared" */ './Blocks/Image/ImageBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.ListLayout.ListLayoutBlock': (theme: string) => import(/* webpackChunkName: "shared" */ './Blocks/ListLayout/ListLayoutBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel': (theme: string) => import(/* webpackChunkName: "shared" */ './Blocks/RowLayout/RowLayoutBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Text.TextBlock': (theme: string) => import(/* webpackChunkName: "shared" */ './Blocks/Text/TextBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.TextAndPicture.TextAndPictureBlock': (theme: string) => import(/* webpackChunkName: "shared" */ './Blocks/TextAndPicture/TextAndPictureBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Video.VideoBlock': (theme: string) => import(/* webpackChunkName: "shared" */ './Blocks/Video/VideoBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.ContentTypes.Media.ImageDataModel': (theme: string) => import(/* webpackChunkName: "media" */ './Partials/ImageDataModel').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Partials.PreviewViewModel': (theme: string) => import(/* webpackChunkName: "shared" */ './Partials/Preview').then(m => ({component: m.default, theme: null})),
  'EPiServer.Core.XhtmlString': (theme: string) => import('./Partials/PropertyTypes/Html').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.SiteBanner.SiteBannerBlock': (theme: string) => import(/* webpackChunkName: "shared" */ './SiteBanner/SiteBannerBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.SiteLayout.Footer.FooterBlock': (theme: string) => import(/* webpackChunkName: "sitelayout" */ '../SiteLayout/Footer').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Start.StartPageViewModel': (theme: string) => import(/* webpackChunkName: "start" */ '../Start/StartPage').then(m => ({component: m.default, theme: null})),
  'EPiServer.Core.ContentArea': (theme: string) => import('@avensia/scope/PropertyTypes/ContentArea').then(m => ({component: m.default, theme: null})),
  'EPiServer.SpecializedProperties.LinkItemCollection': (theme: string) => import('@avensia/scope/PropertyTypes/LinkItemCollection').then(m => ({component: m.default, theme: null})),
  'Avensia.Scope.ServerRenderedHtmlBlock': (theme: string) => import('@avensia/scope/ServerRenderedHtmlBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Scope.ServerRenderedHtmlPage': (theme: string) => import('@avensia/scope/ServerRenderedHtmlPage').then(m => ({component: m.default, theme: null})),
};

// prettier-ignore
setComponentRegistry(componentRegistry);
// prettier-ignore
export default componentRegistry;

// prettier-ignore
const themeContextCreatorRegistry = {};
// prettier-ignore
setThemeContextCreatorRegistry(themeContextCreatorRegistry);
