import React from 'react';
import connect from 'Shared/connect';
import { closeAccountPanel } from './action-creators';
import Account from './index';
import Modal from 'Shared/Modal';
import { Snackbar } from 'SiteLayout/Tray';

type ConnectStateType = {
  accountPanelIsOpen: boolean;
  snackMessage: string;
};

type DispatchPropType = {
  closeAccountPanel: (snackMessage?: string) => void;
};

type PropType = {} & ConnectStateType & DispatchPropType;

export default connect(
  (state): ConnectStateType => ({
    accountPanelIsOpen: state.currentUser.accountPanelIsOpen,
    snackMessage: state.currentUser.snackMessage,
  }),
  (dispatch): DispatchPropType => ({
    closeAccountPanel() {
      return dispatch(closeAccountPanel());
    },
  }),
)(function AccountPanel(props: PropType) {
  return (
    <React.Fragment>
      <Modal isOpen={props.accountPanelIsOpen} onClose={props.closeAccountPanel}>
        <Account />
      </Modal>
      {props.snackMessage && <Snackbar>{props.snackMessage}</Snackbar>}
    </React.Fragment>
  );
});
