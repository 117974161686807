import React from 'react';
import { styled } from '@glitz/react';
import { shallowEquals, translate, isIE } from '@avensia/scope';
import { Basic } from 'Shared/PageLayout';
import { MainMenuType } from 'Shared/State';
import UserButton from './UserButton';
import SearchIcon from 'Shared/Icon/Search';
import Logotype from 'Shared/Logotype';
import Link from 'Shared/Link';
import { Breakpoint } from '@avensia/scope';
import { media } from '@glitz/core';
import * as style from 'Shared/Style';
import SearchOverlay from './SearchOverlay';

const MENU_HEIGHT = '80px';

type StateType = {
  activeSearchOverlay: boolean;
};

type PropType = {
  mainMenu: MainMenuType;
  activeSearchOverlay: boolean;
};

export default class FullHeader extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      activeSearchOverlay: props.activeSearchOverlay,
    };
  }

  shouldComponentUpdate(nextProps: PropType, nextState: StateType) {
    const shouldUpdate = !shallowEquals(this.props, nextProps, true) || !shallowEquals(this.props, nextState, true);
    if (!shouldUpdate) {
      console.info('Ignoring update to FullHeader');
    }
    return shouldUpdate;
  }

  hide = () =>
    this.setState({
      activeSearchOverlay: false,
    });

  onSearchClick = () => {
    this.setState({ activeSearchOverlay: true });
  };

  render() {
    const { mainMenu } = this.props;
    const middleItems = mainMenu.mainMenuPrimaryItems.slice(0, -1);
    const lastItems = mainMenu.mainMenuPrimaryItems.slice(-1);

    return (
      <>
        <Base>
          <Basic>
            <Base2>
              <Top>
                {!this.state.activeSearchOverlay && (
                  <>
                    <TopLeft>
                      <Home to="/">
                        <Logotype style={{ width: '157px', fill: style.WHITE }} />
                      </Home>
                    </TopLeft>
                    {isIE() ? (
                      <TopMiddle style={{ marginLeft: '28px' }}>
                        {middleItems.map(item => (
                          <MenuItem key={item.url} to={item.url}>
                            <Label>{item.name}</Label>
                          </MenuItem>
                        ))}
                      </TopMiddle>
                    ) : (
                      <TopMiddle style={{ marginLeft: '28px' }}>
                        {middleItems.map(item => (
                          <MenuItem key={item.url} to={item.url}>
                            <Label>{item.name}</Label>
                          </MenuItem>
                        ))}
                      </TopMiddle>
                    )}
                    <TopMiddleRight>
                      {lastItems.map(item => (
                        <MenuItem key={item.url} to={item.url}>
                          <Label>{item.name}</Label>
                        </MenuItem>
                      ))}
                    </TopMiddleRight>
                    <TopRight>
                      <MenuIcons>
                        <SearchIcon style={{ margin: '15px' }} onClick={this.onSearchClick} />
                        <UserButton />
                      </MenuIcons>
                    </TopRight>
                  </>
                )}
                {this.state.activeSearchOverlay && <SearchOverlay onHide={this.hide} />}
              </Top>
            </Base2>
          </Basic>
        </Base>
        <noscript>{translate('/Errors/JavaScriptRequired')}</noscript>
      </>
    );
  }
}

const Base = styled.div({
  position: 'absolute',
  top: 0,
  height: MENU_HEIGHT,
  zIndex: style.ZIndex.Overlay - 1,
  width: '100%',
  backgroundColor: 'rgba(103,56,158,0.7)',
  color: style.primaryText,
  overflow: 'hidden',
});

const Base2 = styled.div({
  backgroundColor: 'rgba(0,0,0,0.15)',
  height: MENU_HEIGHT,
});

const Top = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: MENU_HEIGHT,
  position: 'relative',
  padding: {
    left: '15px',
    right: '15px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  maxWidth: style.maxContentWidth,
});

const Home = styled(Link, {
  display: 'block',
  color: 'inherit',
  marginBottom: style.medium,
  ':hover': {
    color: style.primaryText,
  },
});

const MenuItem = styled(Link, {
  display: 'block',
  fontSize: style.delta,
  color: style.primaryText,
  textDecoration: 'none',
  ':hover': {
    color: style.primaryText,
    textDecoration: 'none',
  },
});

const Label = styled.div({
  ...style.truncate(),
  paddingRight: '24px',
  textTransform: 'uppercase',
});

const TopLeft = styled.div({
  display: 'flex',
});

const TopMiddle = styled(TopLeft, {
  alignItems: 'center',
  flexGrow: 2,
});

const TopMiddleRight = styled(TopLeft, {
  justifyContent: 'flex-end',
  marginRight: 0,
});

const TopRight = styled(TopLeft, {
  justifyContent: 'flex-end',
  height: '100%',
});

const MenuIcons = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
});
