import React from 'react';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import FooterType from './FooterViewModel.type';
import Link from 'Shared/Link';
import * as style from 'Shared/Style';
import Logotype from 'Shared/Logotype';
import RegisterNewsletter from './RegisterNewsletter';
import { Breakpoint, epiPropertyValue } from '@avensia/scope';
import Viewport from 'Shared/Viewport';
import SocialLinks from './Social';

const Home = styled(Link, {
  display: 'inline-block',
  fill: style.WHITE,
  height: '32px',
  width: '128px',
  marginBottom: '35px',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '48px',
    width: '192px',
  }),
});

const Groups = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '29px',
});

const Groups2 = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '15px',
  marginBottom: '29px',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    marginBottom: '131px',
  }),
});

const Group = styled.div({});

const FooterWrapper = styled.div({
  position: 'relative',
  padding: {
    left: '15px',
    right: '15px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  maxWidth: style.maxContentWidth,
});

const GroupItem = styled(Link, {
  fontWeight: 300,
  display: 'block',
  marginRight: '60px',
  padding: { y: style.tiny },
  fontSize: '15px',
  color: style.WHITE,
  textDecoration: 'none',
  ':hover': {
    color: style.WHITE,
    textDecoration: 'underline',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    display: 'inline',
  }),
});

type PropType = {
  footer: FooterType;
};

export default ({ footer }: PropType) => (
  <FooterWrapper>
    <Home to="/">
      <Logotype />
    </Home>
    <Viewport>
      {(isCompact: boolean) =>
        isCompact && (
          <Groups2>
            <Group>
              <RegisterNewsletter footer={footer} />
            </Group>
          </Groups2>
        )
      }
    </Viewport>
    <Groups>
      {footer.linkGroups.map((item, i) => (
        <Group key={i}>
          {item.links.map((linkItem, index) => (
            <GroupItem key={index} to={linkItem.url}>
              {linkItem.title}
            </GroupItem>
          ))}
        </Group>
      ))}
    </Groups>
    {epiPropertyValue(footer.block.showSocialLinks) && <SocialLinks footer={footer} />}
    <Viewport>
      {(isCompact: boolean) =>
        !isCompact && (
          <Groups2>
            <Group>
              <RegisterNewsletter footer={footer} />
            </Group>
          </Groups2>
        )
      }
    </Viewport>
  </FooterWrapper>
);
