import { baseTextColor } from './typography';

// Colors
export const WHITE = 'white';
export const BLACK = 'black';
export const GRAY = 'lightgray';
export const GREEN = '#a5b706';
export const ORANGE = '#E68200';
export const RED = '#FF0000';
export const HexagonGray = '#F6F7F9';

export const primary = '#7540AD';
export const primaryMedium = '#67389e';
export const primaryDark = '#613581';
export const primaryText = WHITE;

export const highlight = '#ffba3d';
export const highlightDark = '#ffa400';
export const highlightText = BLACK;

export const secondaryLightest = '#D7D7D7';
export const secondaryLight = '#58595B';
export const secondary = '#333333';
export const secondaryDark = '#414042';
export const secondaryDarker = '#2B282D';
export const secondaryDarkest = '#231F20';
export const secondaryText = baseTextColor;

export const tertiaryLightest = '#A7A9AC';
export const tertiaryLighter = '#979797';
export const tertiaryLight = '#848484';
export const tertiary = '#939598';
export const tertiaryDark = '#808285';
export const tertiaryDarker = '#6D6E71';
export const tertiaryText = WHITE;

export const monochromeLighter = '#F6F7F9';
export const monochromeLight = '#EBECEC';
export const monochrome = '#E6E7E8';
export const monochromeDark = '#D8D8D8';
export const monochromeText = BLACK;

export const positive = '#3CA300';
export const positiveLight = '#4BCC00';
export const positiveDark = '#2D7A00';
export const positiveText = WHITE;
export const negative = '#A30000';
export const negativeLight = '#CC0000';
export const negativeDark = '#7A0000';
export const negativeText = WHITE;
export const neutral = '#CCC200';
export const neutralLight = '#F5E900';
export const neutralDark = '#A39B00';
