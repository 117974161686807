import React from 'react';
import { post, updateAppShellData } from '@avensia/scope';
import { translate } from '@avensia/scope/Translate';
import connect from 'Shared/connect';
import UserIcon from 'Shared/Icon/Login';
import { openAccountPanel, setSnackMessage, ACCOUNT_MODE } from 'Account/action-creators';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import FeedbackButton from 'Shared/Button/Feedback';
import LogoutIcon from 'Shared/Icon/Logout';
import { styled } from '@glitz/react';

type ConnectStateType = {
  isLoggedIn: boolean;
  fullName: string;
  currentBreakpoint: number;
};

type DispatchPropType = {
  updateAppShellData: () => Promise<any>;
  openAccountPanel: (mode: ACCOUNT_MODE) => void;
  setSnackMessage: (snackMessage: string) => void;
};

const AccountIcon = styled(UserIcon, {
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const StyledLogout = styled(LogoutIcon, {
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 'auto',
});

type PropType = ConnectStateType & DispatchPropType & {};

class LoginLogout extends React.Component<PropType> {
  doLogout() {
    return post('/dologout')
      .then(() => {
        return this.props.updateAppShellData();
      })
      .then(() => {
        this.props.setSnackMessage(translate('/Account/Logout/Successful'));
      });
  }

  onLoginClick = () => {
    this.props.openAccountPanel(ACCOUNT_MODE.Login);
  };

  onLogoutClick = () => this.doLogout();

  render() {
    return (
      <React.Fragment>
        {this.props.isLoggedIn ? (
          <FeedbackButton
            appearance={[ButtonAppearance.Bare]}
            onClick={this.onLogoutClick}
            style={{ paddingLeft: '7px', paddingRight: '15px' }}
          >
            <StyledLogout />
          </FeedbackButton>
        ) : (
          <Button
            appearance={[ButtonAppearance.Bare]}
            onClick={this.onLoginClick}
            style={{ paddingLeft: '7px', paddingRight: '15px' }}
          >
            <AccountIcon />
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
    fullName: !!state.currentUser && state.currentUser.fullName,
    currentBreakpoint: state.currentBreakpoint,
  }),
  (dispatch): DispatchPropType => ({
    updateAppShellData() {
      return dispatch(updateAppShellData());
    },
    openAccountPanel(mode: ACCOUNT_MODE) {
      return dispatch(openAccountPanel(mode));
    },
    setSnackMessage(snackMessage: string) {
      return dispatch(setSnackMessage(snackMessage));
    },
  }),
)(LoginLogout as React.ComponentClass<PropType>);
