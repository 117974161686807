import { appShellReducer, currentPageReducer, themeReducer, breakpointReducer, spinnerReducer } from '@avensia/scope';
import mainMenuReducer from 'SiteLayout/MainMenu/reducer';
import footerReducer from 'SiteLayout/Footer/reducer';
import currentUserReducer from 'Account/reducer';
import systemTextReducer from 'Shared/SystemText/reducer';
import StateType, { Action } from './State';
import deepFreeze from './deep-freeze';

export default (state: StateType, action: Action): StateType => {
  const appShellData = appShellReducer(state.appShellData, action);
  // We deep freeze the state to make sure we don't accidentally mutate it
  // Note that freezing objects is quite expensive, so we only do it in
  // dev builds

  return deepFreeze({
    appShellData,
    currentBreakpoint: breakpointReducer(state.currentBreakpoint, action, appShellData),
    currentPage: currentPageReducer(state.currentPage, action, appShellData),
    mainMenu: mainMenuReducer(state.mainMenu, action, appShellData),
    footer: footerReducer(state.footer, action, appShellData),
    currentUser: currentUserReducer(state.currentUser, action, appShellData),
    currentTheme: themeReducer(state.currentTheme, state.appShellData, action),
    spinner: spinnerReducer(state.spinner, action),
    systemText: systemTextReducer(state.systemText, action),
  });
};
