import { addUserLog, currentUrl, URLX } from '@avensia/scope';
import State, { Dispatch, Action, Store } from 'Shared/State';
import { batchActions } from 'redux-batched-actions';

export type OpenAccountPanelAction = Action & {
  hardReloadToAfterLogin?: URLX;
};
export type CloseAccountPanelAction = Action & {};

export type OpenSubscribePanelAction = Action & {};
export type CloseSubscribePanelAction = Action & {};

export type SetSnackMessageAction = Action & {
  snackMessage: string;
};

export type SetActiveAccountModeAction = Action & {
  mode: ACCOUNT_MODE;
};

export type UpdateEmailAction = Action & {
  email: string;
};

export type OpenConfirmationPanelAction = Action & {};
export type CloseConfirmationPanelAction = Action & {};

export const CLOSE_ACCOUNT_PANEL = 'CLOSE_ACCOUNT_PANEL';
export const OPEN_ACCOUNT_PANEL = 'OPEN_ACCOUNT_PANEL';
export const CLOSE_SUBSCRIBE_PANEL = 'CLOSE_SUBSCRIBE_PANEL';
export const OPEN_SUBSCRIBE_PANEL = 'OPEN_SUBSCRIBE_PANEL';
export const SET_SNACK_MESSAGE = 'SET_SNACK_MESSAGE';
export const SET_ACTIVE_ACCOUNT_MODE = 'SET_ACTIVE_ACCOUNT_MODE';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const CLOSE_CONFIRMATION_PANEL = 'CLOSE_CONFIRMATION_PANEL';
export const OPEN_CONFIRMATION_PANEL = 'OPEN_CONFIRMATION_PANEL';

export enum ACCOUNT_MODE {
  Login,
  Registration,
  ForgotPassword,
}

export function openConfirmationPanel() {
  addUserLog('Opening Newsletter subscribe overlay');
  return (dispatch: Dispatch) => {
    dispatch(
      batchActions([
        {
          type: OPEN_CONFIRMATION_PANEL,
        } as OpenConfirmationPanelAction,
      ]),
    );
  };
}

export function closeConfirmationPanel() {
  addUserLog('Closing Newsletter subscribe overlay');
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLOSE_CONFIRMATION_PANEL,
    } as CloseConfirmationPanelAction);
  };
}

export function openAccountPanel(mode: ACCOUNT_MODE = ACCOUNT_MODE.Login, hardReloadToAfterLogin?: URLX) {
  addUserLog('Opening login/registration overlay');
  return (dispatch: Dispatch) => {
    dispatch(
      batchActions([
        setActiveAccountMode(mode),
        {
          type: OPEN_ACCOUNT_PANEL,
          hardReloadToAfterLogin,
        } as OpenAccountPanelAction,
      ]),
    );
  };
}

export function closeAccountPanel(snackMessage?: string) {
  addUserLog('Closing login/registration overlay');
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLOSE_ACCOUNT_PANEL,
    } as CloseAccountPanelAction);
    if (snackMessage) {
      setSnackMessage(snackMessage, false)(dispatch);
    }
  };
}

export function openSubscribePanel() {
  addUserLog('Opening Newsletter subscribe overlay');
  return (dispatch: Dispatch) => {
    dispatch(
      batchActions([
        {
          type: OPEN_SUBSCRIBE_PANEL,
        } as OpenSubscribePanelAction,
      ]),
    );
  };
}

export function closeSubscribePanel() {
  addUserLog('Closing Newsletter subscribe overlay');
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLOSE_SUBSCRIBE_PANEL,
    } as CloseSubscribePanelAction);
  };
}

export function setSnackMessage(snackMessage: string, persist = false) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SNACK_MESSAGE,
      snackMessage,
    } as SetSnackMessageAction);

    if (!persist && !!snackMessage) {
      setTimeout(() => {
        dispatch(setSnackMessage(null));
      }, 10000);
    }
  };
}

export function setActiveAccountMode(mode: ACCOUNT_MODE) {
  addUserLog('Changing login/registration overlay mode');
  return {
    type: SET_ACTIVE_ACCOUNT_MODE,
    mode,
  } as SetActiveAccountModeAction;
}

export function openLoginBoxIfUrlSaysSo(store: Store) {
  const queryParam = 'login';
  const queryParamValue = 'required';
  const returnUrlQueryParam = 'ReturnUrl';

  const url = currentUrl();
  const returnUrl = url.searchParams.has(returnUrlQueryParam)
    ? new URLX(url.searchParams.get(returnUrlQueryParam))
    : null;

  if (url.searchParams.get(queryParam) === queryParamValue && !(store.getState() as State).currentUser.isLoggedIn) {
    // So this is quite icky, but since Shared/Modal uses componentDidMount to get the DOM node to create a portal
    // for we will end up firing this action before componentDidMount happens. Which means that Shared/Modal won't
    // render anything (yet).
    setTimeout(() => {
      store.dispatch(openAccountPanel(ACCOUNT_MODE.Login, returnUrl));
    });
  }
}

export function updateEmail(email: string) {
  return {
    type: UPDATE_EMAIL,
    email,
  } as UpdateEmailAction;
}
