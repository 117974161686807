import React from 'react';
import Button, { PropType as ButtonPropType } from './index';
import { styled } from '@glitz/react';

type PropType = ButtonPropType & {
  fill?: string;
  color?: string;
};

const HexagonButton = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Hexagon = (props: PropType) => {
  const { appearance, color, ...restProps } = props;
  return (
    <HexagonButton className="hexagonbutton">
      <Button
        appearance={appearance}
        {...restProps}
        style={{
          fontSize: '16px',
          textDecoration: 'none',
          textShadow: 'none',
          clipPath: 'polygon(6% 0, 94% 0, 100% 50%, 94% 100%, 6% 100%, 0 50%)',
          minWidth: '15rem',
          paddingLeft: '2em',
          paddingRight: '2em',
        }}
      />
    </HexagonButton>
  );
};

export default Hexagon;
