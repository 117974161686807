import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import { Right } from 'Shared/Icon/Arrow_forward';
import MainMenuItemType from '../MainMenu/MainMenuItem.type';
import * as style from 'Shared/Style';

type LinkPropType = {
  url: string;
};

type ItemPropType = {
  pushLevel?: (item: MainMenuItemType) => void;
  item: MainMenuItemType;
};

type PropType = LinkPropType | ItemPropType;

function isLink(props: PropType): props is LinkPropType {
  return 'url' in props;
}

export const LinkStyling = {
  display: 'block',
  fontSize: style.delta,
  color: style.BLACK,
  alignItems: 'center',
  textDecoration: 'none',
  ':hover': {
    color: style.BLACK,
    textDecoration: 'none',
  },
  padding: {
    left: '21px',
    right: '16px',
    bottom: '15px',
  },
  height: 'auto',
};

const LinkStyled = styled(Link, LinkStyling);

export const Label = styled.span({
  textTransform: 'capitalize',
  height: 'auto',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

const Arrow = styled(Right, {
  maxWidth: '12px',
  maxHeight: '12px',
  color: style.primary,
  fontSize: '1em',
  marginBottom: '4px',
});

const ArrowButton = styled.span({
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default class Item extends React.Component<PropType> {
  isParent(props: PropType): props is ItemPropType {
    return (
      !isLink(this.props) && this.props.pushLevel && this.props.item.children && this.props.item.children.length > 0
    );
  }
  onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (this.isParent(this.props)) {
      e.preventDefault();
      this.props.pushLevel(this.props.item);
    }
  };
  render() {
    if (isLink(this.props)) {
      return (
        <LinkStyled to={this.props.url}>
          <Label>{this.props.children}</Label>
        </LinkStyled>
      );
    } else {
      const { url, name } = this.props.item;
      return (
        <LinkStyled to={url}>
          {this.isParent(this.props) ? (
            <>
              <Label>
                {name}
                <ArrowButton onClick={this.onClick}>
                  <Arrow />
                </ArrowButton>
              </Label>
            </>
          ) : (
            <Label>{name}</Label>
          )}
        </LinkStyled>
      );
    }
  }
}
