import React from 'react';
import PropTypes from 'prop-types';
import { styled, StyledProps, applyClassName, StyledElementProps } from '@glitz/react';
import { shallowEquals, Breakpoint, widthFromBreakpoint } from '@avensia/scope';
import appearance, { AppearanceType } from 'Shared/appearance';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';

export { default as Heading, AppearanceHeading } from './Heading';

export enum Appearance {
  /** Content is stretched to the edges if viewport with is less than max page width */
  Narrow,
  Normal,
  Wide,
  Gap,
  Part,
  BackgroundImage,
  Center,
}

export enum Layout {
  OneToOne,
  TwoToThree,
  ThreeToTwo,
  OneToFour,
  FourToOne,
}

type LayoutPropType = {
  fraction: number;
  order: number;
};

// Gives a layout based on visual order for wider windows like desktop,
// but sorts columns by ratio descendingly so the greatest ratio will be
// the first child and the less ratio will be the last
//
// E.g. `[3, 5, 2]` will display a layout like:
// ________________________
// |30%___|50%_______|20%_|
//
// But DOM order and visual order för narrower windows will be:
// ________________________
// |50%_______|30%___|20%_|
function entity(layout: number[]): LayoutPropType[] {
  const total = layout.reduce((a, b) => a + b, 0);
  return layout
    .map((ratio, order) => ({
      order,
      fraction: ratio / total,
    }))
    .sort((a, b) => b.fraction - a.fraction);
}

const entities = {
  [Layout.OneToOne]: entity([1, 1]),
  [Layout.TwoToThree]: entity([2, 3]),
  [Layout.ThreeToTwo]: entity([3, 2]),
  [Layout.OneToFour]: entity([1, 4]),
  [Layout.FourToOne]: entity([4, 1]),
};

const BREAKPOINT = Breakpoint.Medium;
const COLUMN_GAP = style.Margin.Small;
const DEFAULT_ROW_GAP = style.Margin.Large;

const Child: React.StatelessComponent<LayoutPropType> = ({ fraction, order, children }) => (
  <styled.Div
    css={style.responsiveMargin((margin, breakpoint) => {
      if (breakpoint >= BREAKPOINT) {
        // Layouts below `BREAKPOINT` are stacked below each other
        const gap = style.pixelsToUnit(margin(COLUMN_GAP));
        return {
          width: `calc(${fraction * 100}% - (${gap} - ${gap} * ${fraction}))`,
          order,
        };
      }
    })}
  >
    {children}
  </styled.Div>
);

const contextTypes = {
  layout: PropTypes.object,
};

type ContextType = {
  layout: {
    hasColumnGap: boolean;
    hasRowGap: boolean;
    rowMargin: style.Margin;
  };
};

type PropType<TElement> = {
  appearance?: AppearanceType<Appearance>;
  layout?: Layout;
  title?: string;
  elementRef?: React.Ref<TElement>;
};

export function factory<TElement extends HTMLElement>(TagName: string, defaults: AppearanceType<Appearance> = []) {
  type FactoryPropType = PropType<TElement> & React.HTMLAttributes<TElement>;

  const Base = styled(
    applyClassName(
      class Layout extends React.Component<FactoryPropType & StyledElementProps> {
        static childContextTypes = contextTypes;
        static contextTypes = contextTypes;
        childContext: ContextType;
        constructor(props: FactoryPropType, context: ContextType) {
          super(props, context);
          this.childContext = {
            layout: this.layoutContext(props, context),
          };
        }
        getChildContext() {
          // Yes, we use context here and if this inspires you for another feature
          // please have a look here first: https://twitter.com/dan_abramov/status/749715530454622208
          return this.childContext;
        }
        componentWillReceiveProps(nextProps: FactoryPropType, nextContext: ContextType) {
          const layout = this.layoutContext(nextProps, nextContext);
          if (!shallowEquals(this.childContext.layout, layout)) {
            this.childContext = {
              layout,
            };
          }
        }
        hasGap(props: FactoryPropType, context: ContextType) {
          const appear = appearance(([] as Appearance[]).concat(defaults, props.appearance));
          const hasColumnGap = (context.layout && context.layout.hasColumnGap) || appear(Appearance.Gap);
          const hasRowGap = (context.layout && context.layout.hasRowGap) || appear(Appearance.Part);
          return { hasColumnGap, hasRowGap };
        }
        layoutContext(props: FactoryPropType, context: ContextType) {
          return { ...this.hasGap(props, context), rowMargin: (context.layout || ({} as any)).rowMargin };
        }
        render() {
          const {
            // tslint:disable-next-line:no-unused-variable
            appearance,
            layout,
            elementRef,
            children,
            ...restProps
          } = this.props;

          let passChildren = children;

          if (typeof this.props.layout === 'number') {
            const entity = entities[layout];
            passChildren = React.Children.map(this.props.children, (child, i) => {
              if (entity[i]) {
                return <Child {...entity[i]}>{child}</Child>;
              }
              if (__DEV__) {
                console.warn(
                  `Child index ${i} was not rendered because it exceeded the number of allowed children of ${
                    entity.length
                  }`,
                );
              }
            });
          }

          return (
            <TagName {...restProps} ref={elementRef}>
              {passChildren}
            </TagName>
          );
        }
      },
    ),
  );

  return styled(({ compose, ...restProps }: FactoryPropType & StyledProps, context: ContextType) => {
    const appear = appearance(([] as Appearance[]).concat(defaults, restProps.appearance));
    const applyColumnGap = appear(Appearance.Gap) && !(context.layout && context.layout.hasColumnGap);
    const applyRowGap = appear(Appearance.Part) && !(context.layout && context.layout.hasRowGap);

    const base = (
      <Base
        {...restProps}
        css={compose({
          margin: { x: 'auto' },
          maxWidth: '100%',
          width: '100%',
          ...(appear(Appearance.Narrow) && {
            width: widthFromBreakpoint(style.pageNarrowBreakpoint),
          }),
          ...(appear(Appearance.Normal) && {
            width: '100%',
          }),
          ...(appear(Appearance.Center) && {
            paddingLeft: '15px',
            paddingRight: '15px',
            width: '100%',
            ...media(style.mediaMinQueries[Breakpoint.Medium], {
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: style.maxContentWidth,
              padding: {
                left: '50px',
                right: '50px',
              },
            }),
            ...media(style.mediaBetweenQuery(Breakpoint.Small, Breakpoint.Medium), {
              padding: {
                left: '30px',
                right: '30px',
                bottom: '48px',
              },
            }),
          }),
          ...(appear(Appearance.Wide) && {
            paddingLeft: 0,
            paddingRight: 0,
            width: '100%',
            ...media(style.mediaMinQueries[Breakpoint.Small], {
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: style.maxContentWidth,
              padding: {
                left: '50px',
                right: '50px',
              },
              margin: {
                bottom: '48px',
              },
            }),
          }),
          ...style.mergeShallow(
            style.responsiveMargin(margin => {
              if (applyColumnGap || applyRowGap) {
                return {
                  ...(applyColumnGap && {
                    maxWidth: `calc(100vw - ${style.pixelsToUnit(margin(COLUMN_GAP))} * 2)`,
                  }),
                  ...(applyRowGap && {
                    marginBottom: margin(
                      context.layout && typeof context.layout.rowMargin === 'number'
                        ? context.layout.rowMargin
                        : DEFAULT_ROW_GAP,
                    ),
                  }),
                };
              }
            }),
            typeof restProps.layout === 'number' && {
              // Layouts below `BREAKPOINT` are stacked below each other
              ['@media ' + style.mediaMinQueries[BREAKPOINT]]: {
                display: 'flex',
                justifyContent: 'space-between',
              },
            },
          ),
        })}
      />
    );
    return base;
  });
}

export const Basic = factory<HTMLDivElement>('div');
export const Part = factory<HTMLDivElement>('div', Appearance.Part);
export const Section = factory('section');
export const Aside = factory('aside');
export const Article = factory('article');
export const Form = factory<HTMLFormElement>('form');
export const Main = factory<HTMLMainElement>('main');

type MarginProviderPropType = {
  margin: style.Margin;
};

export class MarginProvider extends React.Component<MarginProviderPropType> {
  static childContextTypes = contextTypes;
  static contextTypes = contextTypes;
  childContext: ContextType;
  constructor(props: MarginProviderPropType, context: ContextType) {
    super(props, context);
    this.childContext = { layout: { ...context.layout, rowMargin: props.margin } };
  }
  getChildContext() {
    // Yes, we use context here and if this inspires you for another feature
    // please have a look here first: https://twitter.com/dan_abramov/status/749715530454622208
    return this.childContext;
  }
  componentWillReceiveProps(nextProps: MarginProviderPropType, nextContext: ContextType) {
    const layout = { ...nextContext.layout, rowMargin: nextProps.margin };
    if (!shallowEquals(this.childContext.layout, layout)) {
      this.childContext = {
        layout,
      };
    }
  }
  render() {
    return React.Children.only(this.props.children);
  }
}
