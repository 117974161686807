import '../styles/globals.scss';
import React from 'react';
import { AppContainer } from 'react-hot-loader';
import { GlitzClient } from '@glitz/core';
import { GlitzProvider } from '@glitz/react';
import {
  browserRender,
  initUpdateAppShellDataOnSchedule,
  loadPage,
  switchBreakpoint,
  currentBreakpoint,
  setupResizeListener,
  resolveComponentAndChildComponents,
  isBundleLoadError,
  setPhrases,
  addUserLog,
  setStoreForCrossWindowEvents,
  onHistory,
  refreshCachedData,
  hasServiceWorker,
  clearCacheAndUnregisterServiceWorker,
  registerServiceWorker,
} from '@avensia/scope';
import 'Shared/component-registry';
import Container from './SiteLayout/Container';
import State, { PageType, Store } from 'Shared/State';
import AppShellDataType from 'AppShell/AppShellData.type';
import createStore from 'Shared/create-store';
import { openLoginBoxIfUrlSaysSo } from 'Account/action-creators';
import currentPageIsAppShell from './AppShell/Partials/current-page-is-appshell';
import { glitzCoreOptions } from 'Shared/glitz-options';

let firstLoadIsAppShell = false;

async function render(store: Store, glitz: GlitzClient) {
  const el = document.getElementById('container');

  if (!(window as any).Intl) {
    // Price formatting uses `Intl.NumberFormat` which isn't supported for
    // browsers like IE10 and iOS9
    await import('Shared/intl');
  }

  await browserRender(
    store,
    el,
    <GlitzProvider glitz={glitz}>
      <AppContainer>
        <Container store={store} />
      </AppContainer>
    </GlitzProvider>,
  );
}

function initialRender(appShellData: AppShellDataType, currentPage: PageType, glitz: GlitzClient) {
  let initialLog = 'Started at ' + window.location.href + '.';
  if (window.IS_RENDERED_FROM_CACHE) {
    initialLog += ' Was rendered in Service Worker.';
  }
  addUserLog(initialLog);

  const store = createStore({ appShellData, currentPage });

  setStoreForCrossWindowEvents(store);
  setupResizeListener(store);
  initUpdateAppShellDataOnSchedule(store);
  openLoginBoxIfUrlSaysSo(store);

  const breakpoint = currentBreakpoint();
  if (breakpoint !== store.getState().currentBreakpoint) {
    console.debug('Setting new breakpoint since server guess was incorrect');
    store.dispatch(switchBreakpoint(breakpoint));
  } else {
    console.debug('Server breakpoint guess was correct');
  }

  onHistory(['push', 'pop', 'replace'], e => {
    const loadPromise = store.dispatch(
      loadPage({ url: e.url, options: e.options, stateChangeEvent: e, replaceStateOnRedirect: true }),
    );
    loadPromise.then(() => {
      const state = store.getState() as State;
      const currentPage = state.currentPage;
      if (!currentPage.isPartial) {
        setTimeout(() => {
          // gtmPageLoad(currentPage, true, state);
        }, 1000);
      }
    });
    return loadPromise;
  });

  // Make sure phrases isn't `undefined`, otherwise
  // `translate(...)` with cause an exception.
  setPhrases(appShellData.languagePhrases);

  const page = (store.getState() as State).currentPage;
  if (!currentPageIsAppShell(page)) {
    // gtmPageLoad(page, false, store.getState() as State);
  } else {
    firstLoadIsAppShell = true;
  }

  render(store, glitz);

  return store;
}

if (!window.APP_SHELL_DATA || !window.CURRENT_PAGE) {
  if (window.location.href !== '/') {
    setTimeout(() => {
      window.location.href = '/';
    });
  }
} else {
  if (module.hot && module.hot.data && module.hot.data.store && module.hot.data.glitz) {
    const store = module.hot.data.store;
    const glitz = module.hot.data.glitz;

    module.hot.accept();
    module.hot.dispose(data => {
      data.store = store;
      data.glitz = glitz;
    });

    const state = store.getState();

    console.info('HMR updating');
    resolveComponentAndChildComponents([state.currentPage, state.appShellData], state.appShellData.currentTheme).then(
      () => {
        console.info('HMR re-render');
        render(store, glitz);
      },
      e => {
        console.error('HMR reloading due to update error: ', e);
        window.location.reload();
      },
    );
  } else {
    resolveComponentAndChildComponents(
      [window.CURRENT_PAGE, window.APP_SHELL_DATA],
      (window.APP_SHELL_DATA as AppShellDataType).currentTheme,
    ).then(
      () => {
        const glitz = new GlitzClient(glitzCoreOptions);
        const store = initialRender(window.APP_SHELL_DATA, window.CURRENT_PAGE, glitz);

        if (module.hot) {
          module.hot.accept();
          module.hot.dispose(data => {
            data.store = store;
            data.glitz = glitz;
          });
        }

        if (window.IS_RENDERED_FROM_CACHE) {
          refreshCachedData(store).then(() => {
            if (firstLoadIsAppShell) {
              //  gtmPageLoad(page, false, store.getState() as State);
              firstLoadIsAppShell = false;
            }
          });
        }
      },
      e => {
        if (isBundleLoadError(e) && hasServiceWorker) {
          clearCacheAndUnregisterServiceWorker().then(() => {
            window.location.reload();
          });
        }
      },
    );
  }

  registerServiceWorker();

  const oldOnError = window.onerror;
  if (process.env.NODE_ENV !== 'production') {
    // Make device debugging a bit simpler
    // tslint:disable-next-line only-arrow-functions
    window.onerror = function(message, url, line, col, e) {
      const error = document.createElement('div');
      error.style.position = 'fixed';
      error.style.padding = '10px';
      error.style.border = '1px solid red';
      error.style.top = '10px';
      error.style.left = '10px';
      error.style.right = '10px';
      error.style.bottom = '10px';
      error.style.background = 'white';

      const html =
        '<h1>Oh noes!</h1>' +
        '<p>Message: ' +
        message +
        '</p>' +
        '<p>Line: ' +
        line +
        '</p>' +
        '<p>Error: ' +
        JSON.stringify(e, null, 2) +
        '</p>';
      error.innerHTML = html;
      document.body.appendChild(error);
      error.addEventListener('click', () => document.body.removeChild(error));

      if (oldOnError) {
        oldOnError.apply(window, arguments);
      }
    };
  } else {
    printAvensiaSplashToConsole();
  }
}

function printAvensiaSplashToConsole() {
  // tslint:disable-next-line no-string-literal
  if (window['console'] && window['console']['log']) {
    // tslint:disable-next-line no-string-literal
    window['console']['log'](
      '     _                      _       \n' +
        '    / \\__   _____ _ __  ___(_) __ _ \n' +
        "   / _ \\ \\ / / _ | '_ \\/ __| |/ _` |\n" +
        '  / ___ \\ V |  __| | | \\__ | | (_| |\n' +
        ' /_/   \\_\\_/ \\___|_| |_|___|_|\\__,_|\n\n' +
        'Do you end up here a lot? Perhaps you should come work for us!\n' +
        'We build sites using React, Redux, TypeScript, Webpack, CSS-in-JS, etc.\n' +
        'http://www.avensia.com/careers/',
    );
  }
}
