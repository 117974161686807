import Viewport from 'Shared/Viewport';
import React from 'react';
import { EpiProperty, Breakpoint } from '@avensia/scope';
import Breadcrumbs from 'Shared/Breadcrumbs';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import * as style from 'Shared/Style';
import BreadcrumbsViewModel from 'Shared/Breadcrumbs/BreadcrumbsViewModel.type';

const H1 = styled.h1({
  margin: {
    bottom: 0,
    top: '20px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      left: 0,
      top: 0,
    },
  }),
});

const Divider = styled.div({
  height: '1px',
  width: '100%',
  border: {
    top: {
      width: '1px',
      style: 'solid',
      color: style.primaryDark,
    },
  },
});

const narrowStyling = {
  margin: {
    left: '15px',
    right: '15px',
  },
};

export enum AppearanceHeading {
  Narrow,
}

type PropType = {
  breadcrumbs?: BreadcrumbsViewModel[];
  title: Scope.Property<string>;
  appearance?: AppearanceHeading;
};
export default (props: PropType) => {
  return (
    <Viewport>
      {(isCompact: boolean) =>
        isCompact ? (
          <styled.Div css={props.appearance === AppearanceHeading.Narrow ? narrowStyling : null}>
            {props.breadcrumbs && <Breadcrumbs breadcrumbs={props.breadcrumbs} />}
            <Divider />
            <EpiProperty component={H1} for={props.title} />
          </styled.Div>
        ) : (
          <>
            <EpiProperty component={H1} for={props.title} />
            {props.breadcrumbs && <Breadcrumbs breadcrumbs={props.breadcrumbs} />}
            <Divider />
          </>
        )
      }
    </Viewport>
  );
};
