import React from 'react';
import { styled } from '@glitz/react';
import { TransitionMotion, spring, OpaqueConfig } from 'react-motion';
import { itemKey, ROOT_KEY, ItemKeyType } from '../MainMenu/item-helpers';
import Level, { isRoot } from './Level';
import MainMenuItemType from '../MainMenu/MainMenuItem.type';

const TRANSLATE_X_LEFT = -100;
const TRANSLATE_X_RIGHT = 100;

const Base = styled.nav({
  position: 'relative',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
});

const Entry = styled.div({
  position: 'absolute',
  width: '100%',
  top: 0,
  willChange: 'opacity, transform',
});

type PlainStyleType<TValue> = {
  translateX: TValue;
  opacity: TValue;
};

type StylesType<TValue> = {
  key: ItemKeyType;
  data: {
    primary: MainMenuItemType[] | MainMenuItemType;
    secondary: MainMenuItemType[];
    children: React.ReactNode;
  };
  style: PlainStyleType<TValue>;
};

type PropType = {
  primary: MainMenuItemType[] | MainMenuItemType;
  secondary: MainMenuItemType[];
  setLevel: (key: ItemKeyType) => void;
};

export default class Nav extends React.Component<PropType> {
  pushing = false;
  enumerate = 0;
  currentKey: string;
  componentWillReceiveProps(nextProps: PropType) {
    this.pushing = this.isPushing(this.props.primary, nextProps.primary);
    this.enumerate++;
  }
  isPushing(currentPrimary: MainMenuItemType[] | MainMenuItemType, nextPrimary: MainMenuItemType[] | MainMenuItemType) {
    if (isRoot(currentPrimary)) {
      return true;
    }
    if (isRoot(nextPrimary)) {
      return false;
    }
    return (
      currentPrimary.children &&
      currentPrimary.children.length > 0 &&
      !currentPrimary.children.every(item => item !== nextPrimary)
    );
  }
  enumeratedKey(key: ItemKeyType) {
    return `${key}${this.enumerate}`;
  }
  pushLevel = (item: MainMenuItemType) => {
    this.props.setLevel(itemKey(item));
  };
  getStyles = () => [
    {
      key: (this.currentKey = this.enumeratedKey(isRoot(this.props.primary) ? ROOT_KEY : itemKey(this.props.primary))),
      data: {
        primary: this.props.primary,
        children: this.props.children,
      },
      style: { translateX: spring(0), opacity: spring(1) },
    } as StylesType<OpaqueConfig>,
  ];
  willEnter = (): PlainStyleType<number> => ({
    translateX: this.pushing ? TRANSLATE_X_RIGHT : TRANSLATE_X_LEFT,
    opacity: 0,
  });
  willLeave = (): PlainStyleType<OpaqueConfig> => ({
    translateX: spring(this.pushing ? TRANSLATE_X_LEFT : TRANSLATE_X_RIGHT),
    opacity: spring(0),
  });
  render() {
    return (
      <TransitionMotion styles={this.getStyles} willEnter={this.willEnter} willLeave={this.willLeave}>
        {(interpolatedStyles: Array<StylesType<number>>) => (
          <Base>
            {interpolatedStyles.map(entry => (
              <Entry
                key={entry.key}
                style={
                  entry.style.translateX !== 0 || entry.style.opacity !== 1
                    ? {
                        transform: `translateX(${entry.style.translateX}px)`,
                        opacity: entry.style.opacity,
                        ...(entry.key !== this.currentKey && {
                          pointerEvents: 'none',
                        }),
                      }
                    : null
                }
              >
                <Level primary={entry.data.primary} secondary={this.props.secondary} pushLevel={this.pushLevel} />
              </Entry>
            ))}
          </Base>
        )}
      </TransitionMotion>
    );
  }
}
