import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import { Unchecked as UncheckedRadio, Checked as CheckedRadio } from '../Icon/Radio';
import Check from '../Icon/Check';
import * as style from '../Style';

export type PropType = React.InputHTMLAttributes<HTMLInputElement> & {
  elementRef?: React.Ref<HTMLInputElement>;
};

type StateType = {
  checked: boolean;
  invalid: boolean;
};

const UncheckedCheckbox = styled.span({
  height: '18px',
  width: '18px',
  position: 'relative',
  top: 1,
  border: {
    xy: { width: 2, style: 'solid', color: style.primary },
  },
  marginRight: '5px',
});
const CheckedCheckBox = styled.span({
  height: '18px',
  width: '18px',
  position: 'relative',
  top: 1,
  background: style.primary,
  color: style.WHITE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '5px',
});

class Toggle extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      checked: typeof props.checked === 'undefined' ? false : props.checked,

      invalid: false,
    };
  }
  componentWillReceiveProps(nextProps: PropType) {
    const checked = typeof nextProps.checked === 'undefined' ? this.state.checked : nextProps.checked;
    if (this.props.checked !== checked) {
      this.setState({ checked });
    }
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    if (typeof this.props.checked === 'undefined') {
      const el = e.currentTarget;
      const checked = el.checked;
      if (checked !== this.state.checked) {
        this.setState({ checked });
      }

      const invalid = !el.validity.valid;
      if (invalid !== this.state.invalid) {
        this.setState({ invalid });
      }
    }
  };
  onInvalid = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (this.props.onInvalid) {
      this.props.onInvalid(e);
    }
    this.setState({ invalid: true });
  };
  render() {
    const { children, className, elementRef, style: styleProp, ...restProps } = this.props;

    return (
      <label className={className} style={styleProp}>
        <Input
          css={{
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer',
            height: 0,
            width: 0,
          }}
          {...restProps}
          onChange={this.onChange}
          type={this.props.type}
          onInvalid={this.onInvalid}
          innerRef={elementRef}
        />
        {this.props.type === 'checkbox' ? (
          this.state.checked ? (
            <CheckedCheckBox>
              <Check />{' '}
            </CheckedCheckBox>
          ) : (
            <UncheckedCheckbox />
          )
        ) : this.state.checked ? (
          <CheckedRadio />
        ) : (
          <UncheckedRadio />
        )}
        {children}
      </label>
    );
  }
}

export type ToggleType = 'radio' | 'checkbox';

export function factory(type: ToggleType) {
  return styled(applyClassName((props: PropType) => <Toggle {...props} type={type} />), {
    display: 'inline-flex',
    alignItems: 'center',
  });
}

export const Radio = factory('radio');

export const Checkbox = factory('checkbox');

const Input = styled.input(style.visuallyHidden());
