import { Style } from '@glitz/type';
import { pixelsToUnit, Unit } from './unit';
import { small, medium, large, gigantic } from './values';
import { baseLineHeight, zeta, delta, epsilon } from './typography';

// Used to make sure some elements are styled the same, e.g. buttons and fields

export enum General {
  Default,
  Small,
  Large,
}

const lineHeightRatio = pixelsToUnit(baseLineHeight, Unit.Em);

export const generals = {
  [General.Default]: {
    height: `calc(${lineHeightRatio} + ${medium}px * 2)`,
    padding: [medium, large],
    fontSize: epsilon,
  },
  [General.Small]: {
    height: `calc(${lineHeightRatio} + ${small}px * 2)`,
    padding: [small, medium],
    fontSize: zeta,
  },
  [General.Large]: {
    height: `calc(${lineHeightRatio} + ${large}px * 2)`,
    padding: [large, gigantic],
    fontSize: delta,
  },
};

type PropType = {
  type?: General;
  verticalPadding?: string | 0;
  horizontalPadding?: string | 0;
};

export function general(props: PropType = {}): Style {
  const { type = General.Default } = props;
  const { padding, height, fontSize } = generals[type];
  const { verticalPadding = padding[0], horizontalPadding = padding[1] } = props;

  return {
    height,
    padding: { x: horizontalPadding, y: verticalPadding },
    lineHeight: lineHeightRatio,
    fontSize,
  };
}
