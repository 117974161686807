// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// scope.cmd frontend:build-component-registry
// instead.

/* tslint:disable */

// prettier-ignore
export default {
  Avensia_Common_Features_Account_ForgotPassword_ForgotPasswordBlock: 'Avensia.Common.Features.Account.ForgotPassword.ForgotPasswordBlock',
  Avensia_Common_Features_Account_Login_LoginBlock: 'Avensia.Common.Features.Account.Login.LoginBlock',
  Avensia_Common_Features_Account_Register_RegisterBlock: 'Avensia.Common.Features.Account.Register.RegisterBlock',
  Avensia_Common_Features_AppShell_Partials_AppShellViewModel: 'Avensia.Common.Features.AppShell.Partials.AppShellViewModel',
  Avensia_Common_Features_Contact_ContactPageViewModel: 'Avensia.Common.Features.Contact.ContactPageViewModel',
  Avensia_Common_Features_Content_ContentPageViewModel: 'Avensia.Common.Features.Content.ContentPageViewModel',
  Avensia_Common_Features_EditorialContent_Events_EventListEditorialContentPageViewModel: 'Avensia.Common.Features.EditorialContent.Events.EventListEditorialContentPageViewModel',
  Avensia_Common_Features_EditorialContent_Events_EventPageViewModel: 'Avensia.Common.Features.EditorialContent.Events.EventPageViewModel',
  Avensia_Common_Features_EditorialContent_News_NewsPageViewModel: 'Avensia.Common.Features.EditorialContent.News.NewsPageViewModel',
  Avensia_Common_Features_EditorialContent_Events_NewsListEditorialContentPageViewModel: 'Avensia.Common.Features.EditorialContent.Events.NewsListEditorialContentPageViewModel',
  Avensia_Common_Features_Error_Pages_PageNotFoundViewModel: 'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel',
  Avensia_Common_Features_Marketing_MarketingPageViewModel: 'Avensia.Common.Features.Marketing.MarketingPageViewModel',
  Avensia_Common_Features_ResetPassword_ResetPasswordPageViewModel: 'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel',
  Avensia_Common_Features_Search_SearchPageResultViewModel: 'Avensia.Common.Features.Search.SearchPageResultViewModel',
  Avensia_Common_Features_Shared_Blocks_Attachment_AttachmentBlock: 'Avensia.Common.Features.Shared.Blocks.Attachment.AttachmentBlock',
  Avensia_Common_Features_Shared_Blocks_Html_HtmlBlock: 'Avensia.Common.Features.Shared.Blocks.Html.HtmlBlock',
  Avensia_Common_Features_Shared_Blocks_Image_ImageBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_ListLayout_ListLayoutBlock: 'Avensia.Common.Features.Shared.Blocks.ListLayout.ListLayoutBlock',
  Avensia_Common_Features_Shared_Blocks_RowLayout_RowLayoutBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Text_TextBlock: 'Avensia.Common.Features.Shared.Blocks.Text.TextBlock',
  Avensia_Common_Features_Shared_Blocks_TextAndPicture_TextAndPictureBlock: 'Avensia.Common.Features.Shared.Blocks.TextAndPicture.TextAndPictureBlock',
  Avensia_Common_Features_Shared_Blocks_Video_VideoBlock: 'Avensia.Common.Features.Shared.Blocks.Video.VideoBlock',
  Avensia_Common_ContentTypes_Media_ImageDataModel: 'Avensia.Common.ContentTypes.Media.ImageDataModel',
  Avensia_Common_Features_Shared_Partials_PreviewViewModel: 'Avensia.Common.Features.Shared.Partials.PreviewViewModel',
  EPiServer_Core_XhtmlString: 'EPiServer.Core.XhtmlString',
  Avensia_Common_Features_Shared_SiteBanner_SiteBannerBlock: 'Avensia.Common.Features.Shared.SiteBanner.SiteBannerBlock',
  Avensia_Common_Features_SiteLayout_Footer_FooterBlock: 'Avensia.Common.Features.SiteLayout.Footer.FooterBlock',
  Avensia_Common_Features_Start_StartPageViewModel: 'Avensia.Common.Features.Start.StartPageViewModel',
  EPiServer_Core_ContentArea: 'EPiServer.Core.ContentArea',
  EPiServer_SpecializedProperties_LinkItemCollection: 'EPiServer.SpecializedProperties.LinkItemCollection',
  Avensia_Scope_ServerRenderedHtmlBlock: 'Avensia.Scope.ServerRenderedHtmlBlock',
  Avensia_Scope_ServerRenderedHtmlPage: 'Avensia.Scope.ServerRenderedHtmlPage',
};
