import React from 'react';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import { LinkProps, isIOS } from '@avensia/scope';
import RippleEffect, { rippleContainerStyle } from '../RippleEffect';
import * as style from '../Style';
import appearanceFunc, { AppearanceType } from '../appearance';
import Link from 'Shared/Link';

export { General as Variant } from '../Style';
export enum Appearance {
  Primary,
  Secondary,
  Tertiary,
  Highlight,
  Disabled,
  Link,
  Monochrome,
  Pale,
  Negative,
  Positive,
  Bare,
  Full,
  Square,
  Round,
}

type BodyPropType = {
  variant?: style.General | false;
  appearance?: AppearanceType<Appearance>;
  noRippleEffect?: boolean;
};

export type ButtonLinkPropType = LinkProps & BodyPropType;

export type ButtonNotLinkPropType = React.ButtonHTMLAttributes<HTMLButtonElement> & BodyPropType;

// Should be union typed when this is within reach:
// https://github.com/Microsoft/TypeScript/issues/13526
export type PropType = Partial<ButtonLinkPropType> & ButtonNotLinkPropType;

function isLink(props: ButtonLinkPropType | ButtonNotLinkPropType): props is ButtonLinkPropType {
  return 'to' in props;
}

const ButtonLink = styled(
  ({
    compose,
    variant,
    appearance,
    // Disable ripple effect per default because links often disappears when they are clicked
    noRippleEffect = true,
    children,
    ...restProps
  }: StyledProps & ButtonLinkPropType) => (
    <RippleEffect>
      {(ripples: Array<React.ReactElement<any>>, push: (e: React.MouseEvent<HTMLElement>) => Promise<void>) => (
        <Link
          {...restProps}
          css={compose()}
          onClick={e => {
            if (!noRippleEffect) {
              push(e);
            }
            if (restProps.onClick) {
              restProps.onClick(e);
            }
          }}
        >
          {children}
          {ripples}
        </Link>
      )}
    </RippleEffect>
  ),
);

const ButtonNotLink = styled(
  ({
    compose,
    type = 'button',
    variant,
    appearance,
    noRippleEffect,
    children,
    ...restProps
  }: StyledProps & ButtonNotLinkPropType) => (
    <RippleEffect>
      {(ripples: Array<React.ReactElement<any>>, push: (e: React.MouseEvent<HTMLElement>) => Promise<void>) => (
        <styled.Button
          {...restProps}
          type={type}
          css={compose()}
          onClick={e => {
            if (!noRippleEffect) {
              push(e);
            }
            if (restProps.onClick) {
              restProps.onClick(e);
            }
          }}
        >
          {children}
          {ripples}
        </styled.Button>
      )}
    </RippleEffect>
  ),
);

export default styled(function Button({ compose, ...restProps }: StyledProps & PropType) {
  const { variant = style.General.Default } = restProps;
  const appear = appearanceFunc(restProps.appearance);
  const css: Style = {
    font: {
      family: 'inherit',
      size: 'inherit',
      style: 'inherit',
      variant: 'inherit',
      weight: 'inherit',
    },
    border: { xy: { width: 0 } },
    display: 'inline-block',
    padding: { xy: 0 },
    userSelect: 'none',
    textDecoration: 'none',
    textAlign: 'inherit',
    ':focus': {
      outlineWidth: 0,
    },
    ...(variant !== false &&
      style.general({
        type: variant,
        ...((appear(Appearance.Round) || appear(Appearance.Square) || appear(Appearance.Full)) && {
          horizontalPadding: 0,
        }),
      })),
    ...(!restProps.disabled && {
      cursor: 'pointer',
    }),
    ...(appear(Appearance.Bare) && {
      color: 'inherit',
      backgroundColor: ['transparent', 'initial'],
      ':hover': {
        backgroundColor: ['transparent', 'initial'],
      },
    }),
    ...style.transition({ property: ['color', 'background', 'opacity'] }),
    ...((appear(Appearance.Primary) ||
      appear(Appearance.Secondary) ||
      appear(Appearance.Tertiary) ||
      appear(Appearance.Highlight) ||
      appear(Appearance.Disabled)) && {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      minWidth: '118px',
      width: 'auto',
      height: '3em',
      lineHeight: '3em',
      zIndex: 1,
      cursor: 'pointer',
      border: {
        xy: {
          width: 0,
        },
      },
      padding: {
        xy: 0,
      },
      ...(isIOS() && {
        zIndex: 'auto',
      }),
      font: {
        size: '14px',
        weight: 500,
      },
    }),
    // `primary` appearance as default
    ...(appear(Appearance.Primary) && {
      backgroundColor: style.primary,
      color: style.primaryText,
      textDecoration: 'none',
      textAlign: 'center',
      ':hover': {
        transform: 'scale(1.1)',
        transformOrigin: 'center center',
        backgroundColor: style.highlight,
        color: style.highlightText,
      },
    }),
    ...(appear(Appearance.Secondary) && {
      color: style.primaryDark,
      textDecoration: 'underline',
      backgroundColor: style.WHITE,
      border: {
        y: {
          width: '1px',
          color: style.primaryDark,
          style: 'solid',
        },
        x: {
          width: 0,
        },
      },
      padding: {
        left: '3px',
      },
      ':hover': {
        color: style.primary,
      },
    }),
    ...(appear(Appearance.Tertiary) && {
      color: style.WHITE,
      textDecoration: 'underline',
      border: {
        top: {
          width: '1px',
          color: style.WHITE,
          style: 'solid',
        },
        bottom: {
          width: '1px',
          color: style.WHITE,
          style: 'solid',
        },
      },
      padding: {
        left: '3px',
      },
      ':hover': {
        color: style.WHITE,
      },
    }),
    ...(appear(Appearance.Highlight) && {
      color: style.highlightText,
      backgroundColor: style.highlightDark,
      textDecoration: 'none',
      textAlign: 'center',
      ':hover': {
        transform: 'scale(1.1)',
        transformOrigin: 'center center',
        backgroundColor: style.highlight,
        color: style.highlightText,
      },
    }),
    ...(appear(Appearance.Disabled) && {
      color: style.primaryText,
      textDecoration: 'none',
      textAlign: 'center',
      cursor: 'auto',
      backgroundColor: style.tertiaryDark,
    }),
    ...(appear(Appearance.Link) && {
      backgroundColor: 'transparent',
      height: '3em',
      lineHeight: '3em',
      color: style.primary,
      fontSize: '16px',
      cursor: 'pointer',
      fontWeight: 500,
      padding: { xy: 0 },
      textDecoration: 'underline',
      border: {
        xy: {
          width: 0,
        },
      },
    }),
    ...(appear(Appearance.Monochrome) && {
      color: style.monochromeText,
      backgroundColor: style.monochrome,
      ':hover': {
        backgroundColor: style.monochromeDark,
      },
    }),
    ...(appear(Appearance.Pale) && {
      color: style.baseTextColor,
      backgroundColor: 'white',
      border: {
        xy: {
          style: 'solid',
          width: 'thin',
          color: style.monochrome,
        },
      },
      ':hover': {
        backgroundColor: style.monochromeLight,
      },
    }),
    ...(appear(Appearance.Negative) && {
      color: style.negativeText,
      backgroundColor: style.negative,
      ':hover': {
        backgroundColor: style.negativeDark,
      },
    }),
    ...(appear(Appearance.Positive) && {
      color: style.positiveText,
      backgroundColor: style.positive,
      ':hover': {
        backgroundColor: style.positiveDark,
      },
    }),
    ...((appear(Appearance.Round) || appear(Appearance.Square) || appear(Appearance.Full)) && {
      textAlign: 'center',
    }),
    ...((appear(Appearance.Round) || appear(Appearance.Square)) &&
      typeof variant === 'number' && {
        minWidth: style.generals[variant].height,
        fontSize: style.generals[variant].fontSize,
      }),
    ...(appear(Appearance.Square) && {
      color: style.primaryText,
      backgroundColor: style.BLACK,
      height: '75px',
      width: '74px',
      ':hover': {
        backgroundColor: style.BLACK,
      },
    }),
    ...(appear(Appearance.Full) && {
      minWidth: '100%',
    }),
    ...(!restProps.noRippleEffect && rippleContainerStyle),
  };

  return isLink(restProps) ? (
    <ButtonLink {...restProps} css={compose(css)} />
  ) : (
    <ButtonNotLink {...restProps} css={compose(css)} />
  );
});
