import { PAGE_LOAD, UpdateAppShellDataAction, UPDATE_APP_SHELL_DATA } from '@avensia/scope';
import { MainMenuType, Action } from 'Shared/State';
import { OPEN_MAIN_MENU, CLOSE_MAIN_MENU, TOGGLE_QUICK_MENU, OpenMainMenuAction } from './action-creators';
import AppShellDataType from 'AppShell/AppShellData.type';

export default function(state: MainMenuType = null, action: Action, appShellData: AppShellDataType): MainMenuType {
  if (state === null) {
    state = { mainMenuOpen: false, openMenuItem: null, quickMenuOpen: false, ...appShellData.mainMenu };
  }
  switch (action.type) {
    case OPEN_MAIN_MENU: {
      const openMainMenuAction = action as OpenMainMenuAction;
      return {
        ...state,
        openMenuItem: openMainMenuAction.menuItem,
        mainMenuOpen: true,
      };
    }
    case CLOSE_MAIN_MENU: {
      return {
        ...state,
        openMenuItem: null,
        mainMenuOpen: false,
      };
    }
    case TOGGLE_QUICK_MENU: {
      return {
        ...state,
        quickMenuOpen: !state.quickMenuOpen,
      };
    }
    case UPDATE_APP_SHELL_DATA: {
      const updateAppShellDataAction = action as UpdateAppShellDataAction<AppShellDataType>;
      return { ...state, ...updateAppShellDataAction.appShellData.mainMenu };
    }
    case PAGE_LOAD: {
      if (state.mainMenuOpen || state.quickMenuOpen) {
        return {
          ...state,
          mainMenuOpen: false,
          openMenuItem: null,
          quickMenuOpen: false,
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
