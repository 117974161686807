import React from 'react';
import Swipable from 'react-swipeable';
import { styled } from '@glitz/react';
import { on } from '@avensia/scope';
import { Motion, spring, OpaqueConfig } from 'react-motion';
import Overlay from 'Shared/Overlay';
import * as style from 'Shared/Style';

const DELTA_THRESHOLD = 20;

export enum Position {
  Left,
  Right,
}

type StyleType<T> = {
  translateX: T;
};

type PropType = {
  open: boolean;
  position: Position;
  toggle: () => void;
  children?: React.ReactNode | ((promise: () => Promise<{}>) => React.ReactNode);
};

type StateType = {
  translateX: number;
};

export default class Flyout extends React.Component<PropType, StateType> {
  mounted = false;
  isSwiping = false;
  previousDeltaX = 0;
  width: number;
  element: HTMLDivElement;
  unsubscribeResize: () => void;
  state: StateType = {
    translateX: 0,
  };
  componentDidMount() {
    this.mounted = true;
    this.updateWidthTranslateX();
    this.unsubscribeResize = on('resize', this.updateWidthTranslateX);
  }
  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeResize();
  }
  componentWillReceiveProps(nextProps: PropType) {
    const translateX = nextProps.open ? (nextProps.position === Position.Left ? this.width : -this.width) : 0;
    if (this.state.translateX !== translateX) {
      this.setState({ translateX });
    }
  }
  updateWidthTranslateX = () => {
    if (this.element) {
      this.width = this.element.offsetWidth;
      const translateX = this.props.open ? (this.props.position === Position.Left ? this.width : -this.width) : 0;
      if (this.mounted && this.state.translateX !== translateX) {
        this.setState({ translateX });
      }
    }
  };
  onSwiping = (e: React.TouchEvent<HTMLElement>, deltaX: number) => {
    const translateX = this.state.translateX + this.previousDeltaX - deltaX;

    this.previousDeltaX = deltaX;
    this.isSwiping = true;

    if (
      ((this.props.position === Position.Left && translateX < this.width) ||
        (this.props.position === Position.Right && translateX > -this.width)) &&
      translateX !== this.state.translateX
    ) {
      this.setState({
        translateX,
      });
    }
  };
  onSwiped = (e: React.TouchEvent<HTMLElement>, deltaX: number) => {
    this.previousDeltaX = 0;
    this.isSwiping = false;

    if (
      (this.props.position === Position.Left && deltaX > DELTA_THRESHOLD) ||
      (this.props.position === Position.Right && deltaX < -DELTA_THRESHOLD)
    ) {
      this.props.toggle();
    } else {
      this.setState({
        translateX: this.props.position === Position.Left ? this.width : -this.width,
      });
    }
  };
  getStyle = (): StyleType<number | OpaqueConfig> => {
    return {
      translateX: this.isSwiping ? this.state.translateX : spring(this.state.translateX),
    };
  };
  stopPropagation = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();
  ref = (el: HTMLDivElement) => (this.element = el);
  render() {
    const Base = this.props.position === Position.Left ? Left : Right;
    return (
      <Overlay onClose={this.props.toggle} enabled={this.props.open}>
        <Swipable onSwiping={this.onSwiping} onSwiped={this.onSwiped} delta={DELTA_THRESHOLD}>
          <Motion style={this.getStyle()}>
            {({ translateX }: StyleType<number>) => (
              <Base
                onClick={this.stopPropagation}
                innerRef={this.ref}
                style={translateX !== 0 ? { transform: `translateX(${translateX}px)` } : null}
              >
                {this.props.children}
              </Base>
            )}
          </Motion>
        </Swipable>
      </Overlay>
    );
  }
}

const flyoutStyled = styled({
  position: 'fixed',
  top: 0,
  height: '100vh',
  width: '23rem',
  maxWidth: `calc(100% - ${style.gigantic}px)`,
  backgroundColor: 'white',
  willChange: 'transform',
  ...style.depth(),
});

const Left = flyoutStyled(styled.Div, { right: '100%' });
const Right = flyoutStyled(styled.Div, { left: '100%' });
