import React from 'react';
import { ROOT_KEY, ItemKeyType, findItem, findParentKey } from '../MainMenu/item-helpers';
import Flyout, { Position } from 'Shared/Flyout';
import Nav from './Nav';
import { MainMenuType } from 'Shared/State';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import ArrowBack from 'Shared/Icon/Arrow_back';
import * as style from 'Shared/Style';
import { styled } from '@glitz/react';

type StateType = {
  visibleKey: string;
};

type PropType = MainMenuType & {
  toggle: (e?: React.SyntheticEvent<{}>) => void;
};

export default class Drawer extends React.Component<PropType, StateType> {
  state: StateType = {
    visibleKey: ROOT_KEY,
  };
  setLevel = (visibleKey: ItemKeyType) => {
    if (this.state.visibleKey !== visibleKey) {
      this.setState({ visibleKey });
    }
  };
  popLevel = () => {
    this.setLevel(findParentKey(this.props.mainMenuPrimaryItems, this.state.visibleKey));
  };
  render() {
    const { mainMenuOpen } = this.props;
    const currentItem = findItem(this.props.mainMenuPrimaryItems, this.state.visibleKey);

    return (
      <Flyout position={Position.Left} toggle={this.props.toggle} open={mainMenuOpen}>
        {this.state.visibleKey !== ROOT_KEY ? (
          <LevelHeader>
            <BackButton appearance={[ButtonAppearance.Round, ButtonAppearance.Bare]} onClick={this.popLevel}>
              <Arrow />
            </BackButton>
            <LevelName>{currentItem.name}</LevelName>
          </LevelHeader>
        ) : (
          <MenuHeader>
            <MenuHeading>{this.props.menuHeading}</MenuHeading>
          </MenuHeader>
        )}
        <Nav
          primary={currentItem ? currentItem : this.props.mainMenuPrimaryItems}
          secondary={this.props.mainMenuSecondaryItems}
          setLevel={this.setLevel}
        />
      </Flyout>
    );
  }
}

const BackButton = styled(Button, {
  paddingTop: 0,
  paddingBottom: 0,
  width: '40px',
  height: '40px',
});

const Arrow = styled(ArrowBack, {
  maxWidth: '12px',
  maxHeight: '12px',
  color: style.primary,
  display: 'flex',
  alignItems: 'center',
  marginLeft: style.large,
});

const LevelHeader = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: {
    top: style.medium,
    bottom: '5px',
  },
});

const MenuHeader = styled.div({
  width: '100%',
  marginTop: style.medium,
  minHeight: '40px',
});

const LevelName = styled.h2({
  fontSize: '16px',
  margin: {
    left: '25%',
    bottom: 0,
  },
});

const MenuHeading = styled.h2({
  fontSize: '16px',
  fontWeight: 500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  marginBottom: '5px',
});
