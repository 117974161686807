import React from 'react';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import Arrow from '../../Shared/Icon/glyphs/Arrow.svg';
import { translate, postJson, Breakpoint } from '@avensia/scope';
import * as style from 'Shared/Style';
import connect from 'Shared/connect';
import { closeSubscribePanel, openSubscribePanel } from '../../../Features/Account/action-creators';
import NewsLetterModal from './NewsLetterModal';
import FooterType from './FooterViewModel.type';

type ConnectStateType = {
  subscribePanelIsOpen: boolean;
};

type DispatchPropType = {
  closeSubscribePanel: () => void;
  openSubscribePanel: () => void;
};

type StateType = {
  email: string;
  successful: boolean;
};

type PropType = {
  footer: FooterType;
} & ConnectStateType &
  DispatchPropType;

const NewsForm = styled.form({});

const NewsInputLabel = styled.label({
  display: 'block',
  fontSize: '16px',
  fontWeight: 300,
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    display: 'inline-block',
  }),
});

const NewsInput = styled.input({
  backgroundColor: style.secondaryDarker,
  color: style.WHITE,
  height: '32px',
  width: '250px',
  fontSize: '16px', // To prevent iOS zooming when focused
  padding: { x: 10, y: 8 },
  borderRight: {
    width: 0,
  },
  borderTop: {
    color: style.tertiary,
    width: 1,
    style: 'solid',
  },
  borderLeft: {
    color: style.tertiary,
    width: 1,
    style: 'solid',
  },
  borderBottom: {
    color: style.tertiary,
    width: 1,
    style: 'solid',
  },
  ':invalid': {
    boxShadow: 'none',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    marginLeft: '32px',
    backgroundColor: style.secondaryDarkest,
    boxSizing: 'border-box',
  }),
  ...media(
    { maxWidth: '360px' },
    {
      width: '170px',
    },
  ),
});

const SubmitButton = styled.button({
  backgroundColor: style.primary,
  color: style.WHITE,
  fontWeight: 500,
  fontSize: '16px',
  height: '32px',
  position: 'relative',
  top: 0,
  minWidth: '65px',

  borderTop: {
    width: 0,
  },
  borderBottom: {
    width: 0,
  },
  borderLeft: {
    width: 0,
  },
  borderRight: {
    width: 0,
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    top: '1px',
  }),
});

class SubscribePanel extends React.Component<PropType, StateType> {
  form: HTMLFormElement;
  constructor(props: PropType) {
    super(props);
    this.state = {
      email: '',
      successful: false,
    };
    this.onSubscribe = this.onSubscribe.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onSubscribeClick = (e: React.FormEvent<HTMLButtonElement>) => {
    const isFormValid = this.form.checkValidity();
    if (isFormValid) {
      e.preventDefault();
      this.props.openSubscribePanel();
    }
  };

  handleChange(event: any) {
    event.preventDefault();
    this.setState({ email: event.target.value });
  }

  onSubscribe() {
    postJson('/newsletter', { email: this.state.email })
      .then((result: any) => {
        if (result.success) {
          this.setState({
            email: '',
            successful: true,
          });
          return Promise.resolve();
        } else {
          return Promise.reject(result.message);
        }
      })
      .catch(error => {
        return Promise.reject(null);
      });
  }

  componentWillReceiveProps(nextProps: PropType) {
    if (nextProps.subscribePanelIsOpen === false) {
      this.setState({ successful: false });
    }
  }

  formRef = (el: HTMLFormElement) => (this.form = el);
  render() {
    return (
      <NewsForm innerRef={this.formRef}>
        <NewsInputLabel htmlFor="RegisterNewsletterInput">{translate('/NewsLetter')}</NewsInputLabel>
        <NewsInput
          id="RegisterNewsletterInput"
          placeholder="email"
          type="email"
          required
          pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$"
          onChange={this.handleChange}
          value={this.state.email}
        />
        <SubmitButton onClick={this.onSubscribeClick}>
          {translate('/Shared/Go')} <Arrow style={{ fill: 'white', position: 'relative', top: -1 }} />
        </SubmitButton>
        <NewsLetterModal
          isOpen={this.props.subscribePanelIsOpen}
          onClose={this.props.closeSubscribePanel}
          onSubmit={this.onSubscribe}
          email={this.state.email}
          footer={this.props.footer}
          successful={this.state.successful}
        />
      </NewsForm>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    subscribePanelIsOpen: state.currentUser.subscribePanelIsOpen,
  }),
  (dispatch): DispatchPropType => ({
    openSubscribePanel() {
      return dispatch(openSubscribePanel());
    },
    closeSubscribePanel() {
      return dispatch(closeSubscribePanel());
    },
  }),
)(SubscribePanel as React.ComponentClass<PropType>);
