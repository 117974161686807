import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import { Root, CurrentPage, ContentArea, isBrowser } from '@avensia/scope';
import connect from 'Shared/connect';
import FullHeader from './Header/Full';
import CompactHeader from './Header/Compact';
import MainFooter from './Footer';
import Drawer from './Drawer';
import Loader from './Loader';
import { TrayProvider, Tray } from './Tray';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';
import { openMainMenu, closeMainMenu } from './MainMenu/action-creators';
import { MainMenuType, PageType, SystemTextType } from 'Shared/State';
import Viewport from 'Shared/Viewport';
import SystemText from 'Shared/SystemText';
import AccountPanel from 'Account/AccountPanel';
import { Breakpoint } from '@avensia/scope';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';
import highlightjs from 'highlight.js/lib/highlight';
import javascript from 'highlight.js/lib/languages/javascript';
import cs from 'highlight.js/lib/languages/cs';
import xml from 'highlight.js/lib/languages/xml';
import java from 'highlight.js/lib/languages/java';
import ContentPageViewModelType from 'Content/ContentPageViewModel.type';

type ConnectedStateType = {
  currentPage: PageType;
  mainMenu: MainMenuType;
  siteBanner: Scope.ContentArea;
  showLoadingSpinner: boolean;
  systemText: SystemTextType;
  currentBreakpoint: number;
};

type ConnectedActionType = {
  openMainMenu: (e?: React.MouseEvent<HTMLElement>, menuItem?: MainMenuItemType) => void;
  closeMainMenu: (e?: React.MouseEvent<HTMLElement>) => void;
};

type PropType = ConnectedStateType & ConnectedActionType;

class Layout extends React.Component<PropType> {
  componentDidMount() {
    if (isBrowser()) {
      const highlightjscss = document.createElement('link');
      highlightjscss.rel = 'stylesheet';
      highlightjscss.href = '//cdnjs.cloudflare.com/ajax/libs/highlight.js/9.15.6/styles/default.min.css';
      document.head.appendChild(highlightjscss);
    }

    highlightjs.registerLanguage('javascript', javascript);
    highlightjs.registerLanguage('csharp', cs);
    highlightjs.registerLanguage('markup', xml);
    highlightjs.registerLanguage('java', java);
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  highlightCode() {
    const nodes = document.querySelectorAll('pre code');
    for (const node of nodes) {
      highlightjs.highlightBlock(node);
    }
  }

  render() {
    renderMeta(this.props.currentPage);

    return (
      <Viewport>
        {(isCompact: boolean) => (
          <TrayProvider>
            <Base>
              <Content id="content-container">
                <CurrentPage />
              </Content>
              {this.props.currentBreakpoint < Breakpoint.Medium ? (
                <CompactHeader
                  openMainMenu={this.props.openMainMenu}
                  open={this.props.mainMenu.quickMenuOpen}
                  activeSearchOverlay={
                    this.props.currentPage.componentName === 'Avensia.Common.Features.Search.SearchPageResultViewModel'
                  }
                />
              ) : (
                <FullHeader
                  mainMenu={this.props.mainMenu}
                  activeSearchOverlay={
                    this.props.currentPage.componentName === 'Avensia.Common.Features.Search.SearchPageResultViewModel'
                  }
                />
              )}
              <MainFooter />
              <Banner {...this.props.siteBanner} />
              <SystemText>{this.props.systemText.text}</SystemText>
              {this.props.currentBreakpoint < Breakpoint.Medium && (
                <Drawer
                  {...this.props.mainMenu}
                  toggle={() =>
                    this.props.mainMenu.mainMenuOpen ? this.props.closeMainMenu() : this.props.openMainMenu()
                  }
                />
              )}
              <Loader visible={this.props.showLoadingSpinner} />
              <Traybar currentBreakpoint={this.props.currentBreakpoint} />
              <AccountPanel />
            </Base>
          </TrayProvider>
        )}
      </Viewport>
    );
  }
}

export default connect(
  (state): ConnectedStateType => {
    return {
      currentPage: state.currentPage,
      mainMenu: state.mainMenu,
      showLoadingSpinner: state.spinner.isVisible,
      siteBanner: state.appShellData.siteBanner,
      systemText: state.systemText,
      currentBreakpoint: state.currentBreakpoint,
    };
  },
  (dispatch): ConnectedActionType => {
    return {
      openMainMenu: (e?: React.MouseEvent<HTMLElement>, menuItem?: MainMenuItemType) => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        dispatch(openMainMenu(menuItem));
      },
      closeMainMenu: (e?: React.MouseEvent<HTMLElement>) => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        dispatch(closeMainMenu());
      },
    };
  },
)(Layout);

const Base = styled(applyClassName(Root), {
  position: 'absolute',
  width: '100%',
  ...media(style.mediaMaxQueries[Breakpoint.Medium], {
    padding: {
      top: '65px',
    },
  }),
});

const Content = styled.div({
  width: '100%',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    minHeight: '100%',
  }),
});

const Banner = styled(applyClassName(ContentArea));

const Traybar = styled(Tray, {
  width: '100%',
});

function renderMeta(currentPage: PageType) {
  if (isBrowser()) {
    const canonicalResource: {} = currentPage;
    let canonical = document.getElementById('link-canonical') as HTMLLinkElement;
    if (isCanonicalResource(canonicalResource)) {
      if (!canonical) {
        canonical = document.createElement('link');
        canonical.rel = 'canonical';
        canonical.id = 'link-canonical';
        document.head.appendChild(canonical);
      }
      console.debug('Updating canonical to', canonicalResource.canonicalUrl);
      canonical.href = canonicalResource.canonicalUrl;
    } else if (canonical) {
      console.debug('Removing canonical');
      document.head.removeChild(canonical);
    }

    const newTitle = generateTitle(currentPage);
    if (document.title === newTitle) {
      return;
    }
    document.title = newTitle;
    Array.from(document.querySelectorAll('meta[data-dynamic]')).forEach(node => {
      node.parentElement.removeChild(node);
    });
    Object.keys((currentPage.meta && currentPage.meta.elements) || {}).forEach(name => {
      const metaElement = document.createElement('meta');
      metaElement.setAttribute('data-dynamic', '1');
      metaElement.setAttribute(currentPage.meta.elements[name].type, name);
      metaElement.setAttribute('content', currentPage.meta.elements[name].value);
      document.head.appendChild(metaElement);
    });
  }
}

const generateTitle = (currentPage: PageType): string => {
  const ContentPage =
    currentPage.componentName === 'Avensia.Common.Features.Content.ContentPageViewModel'
      ? ((currentPage as unknown) as ContentPageViewModelType)
      : null;
  const titleBreadCrumbs = ContentPage
    ? ContentPage.breadcrumbs.slice(1, -1).map(breadcrumb => ` | ${breadcrumb.text}`)
    : [];
  return (
    ((currentPage.meta && currentPage.meta.title) || '') + titleBreadCrumbs.join('') + ' | ' + formatHost(location.host)
  );
};

function formatHost(host: string) {
  const parts = host.split('.');
  if (parts.length !== 3) {
    return host;
  }

  return parts[1].substring(0, 1).toUpperCase() + parts[1].substring(1) + '.' + parts[2];
}

function isCanonicalResource(page: {}): page is Scope.ICanonicalResource {
  return page && 'canonicalUrl' in page;
}
