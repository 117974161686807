import React from 'react';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import Close from 'Shared/Icon/Close';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import { URLX, pushState, translate } from '@avensia/scope';
import { media } from '@glitz/core';
import { Breakpoint, currentUrl } from '@avensia/scope';

type StateType = {
  activeSearchOverlay: boolean;
  searchValue: string;
};

type PropType = {
  onHide: () => void;
};

export default class SearchOverlay extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      activeSearchOverlay: true,
      searchValue: currentUrl().searchParams.get('query'),
    };
  }

  handleChange = (event: any) => {
    this.setState({ searchValue: event.target.value });
  };

  onSearch = async () => {
    if (this.state.searchValue.length <= 2) {
      return;
    } else {
      const URL = new URLX('/search-page');
      URL.searchParams.set('query', this.state.searchValue);
      URL.searchParams.set('offset', '1');
      await pushState(URL);
    }
  };

  render() {
    const { searchValue } = this.state;

    return (
      <SearchContainer>
        <SearchForm onSubmit={this.onSearch}>
          <SearchLabel>Search</SearchLabel>
          <SearchInput
            value={searchValue}
            name={'query'}
            type="text"
            onChange={this.handleChange}
            placeholder={translate('/QuickSearch/Placeholder')}
            autoFocus
          />
          <SearchButton type="submit">Search</SearchButton>
        </SearchForm>
        <CloseButton appearance={ButtonAppearance.Bare} variant={ButtonVariant.Small} onClick={this.props.onHide}>
          <Close />
        </CloseButton>
      </SearchContainer>
    );
  }
}

const SearchContainer = styled.div({
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  backgroundColor: 'rgba(0,0,0,0.1)',
  ...media(style.mediaMaxQueries[Breakpoint.Medium], {
    backgroundColor: style.primaryDark,
  }),
  zIndex: 100,
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    margin: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    margin: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const SearchForm = styled.form({
  margin: {
    x: 'auto',
    y: '5px',
  },
  padding: {
    xy: '6px',
  },
  border: {
    xy: {
      width: 0,
    },
  },
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    padding: {
      x: '8vw',
      y: '16px',
    },
    margin: {
      x: 'auto',
      y: '0',
    },
  }),
  ...media(style.mediaMaxQueries[Breakpoint.Medium], {
    backgroundColor: style.primaryDark,
  }),
});

const SearchLabel = styled.label({
  display: 'none',
});

const SearchInput = styled.input({
  width: '50%',
  padding: {
    x: '7px',
    y: '10px',
  },
  border: {
    xy: {
      width: 0,
    },
  },
  fontSize: '16px', // To prevent iOS zooming when focused
  fontWeight: 500,
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    padding: {
      xy: '12px',
    },
  }),
});

const SearchButton = styled(Button, {
  display: 'inline-block',
  top: '0',
  padding: {
    x: '28px',
    y: '6px',
  },
  height: '42px',
  color: style.WHITE,
  background: style.primary,
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    height: '45px',
    padding: {
      x: '24px',
      y: '12px',
    },
  }),
});

const CloseButton = styled(Button, {
  width: '46px',
  position: 'absolute',
  right: 0,
  top: '25%',
  fontSize: '1.5',
  color: style.WHITE,
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    top: '21px',
  }),
});
