import React from 'react';
import { styled, StyledProps } from '@glitz/react';

type PropType = StyledProps &
  React.HTMLAttributes<HTMLDivElement> & {
    elementRef?: React.Ref<HTMLDivElement>;
  };

export default styled(({ compose, elementRef, children, ...restProps }: PropType) => (
  <Base {...restProps} css={compose()}>
    <Banner>{children}</Banner>
  </Base>
));

const Base = styled.div({});

const Banner = styled.div({});
