import { Style } from '@glitz/type';
import constants from './constants';

const { BASE_FONT_SIZE, BASE_TEXT_COLOR, BASE_LINE_HEIGHT, BASE_STYLE_UNIT } = constants;

export const baseFontSize = BASE_FONT_SIZE;
export const baseTextColor = BASE_TEXT_COLOR;
export const baseLineHeight = BASE_LINE_HEIGHT;
export const baseUnit = BASE_STYLE_UNIT;
export const alternativeFontFamily = 'serif';

// Large font sizes
export const giga = 72;
export const mega = 54;
export const kilo = 36;

// Normal font sizes
export const alpha = 28;
export const beta = 24;
export const gamma = 20;
export const delta = 16;
export const epsilon = 14;
export const zeta = 12;
export const lineHeight = BASE_LINE_HEIGHT / BASE_FONT_SIZE;

// Small font sizes
export const milli = 10;
export const micro = 8;

export function truncate(overflow = 'ellipsis'): Style {
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: overflow,
  };
}
