import { Action, Dispatch } from 'Shared/State';

export const SHOW_SYSTEM_TEXT = 'SHOW_SYSTEM_TEXT';
export const HIDE_SYSTEM_TEXT = 'HIDE_SYSTEM_TEXT';

export type ShowSystemTextAction = Action & {
  text: string;
};

const DEFAULT_TIMEOUT = 5000;

let lastTimer: Timer;

export function showSystemText(text: string, timeout: number | false = DEFAULT_TIMEOUT) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SHOW_SYSTEM_TEXT,
      text,
    } as ShowSystemTextAction);

    if (typeof timeout === 'number') {
      const timer = (lastTimer = setTimeout(() => {
        if (timer === lastTimer) {
          dispatch(hideSystemText());
        }
      }, timeout));
    }
  };
}

export function hideSystemText() {
  return {
    type: HIDE_SYSTEM_TEXT,
  };
}
