import React from 'react';
import { styled } from '@glitz/react';
import Logotype from 'Shared/Logotype';
import Link from 'Shared/Link';
import * as style from 'Shared/Style';
import UserButton from './UserButton';
import SearchIcon from 'Shared/Icon/Search';
import QuickMenu from 'SiteLayout/QuickMenu';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';
import SearchOverlay from './SearchOverlay';

const LOGO_HEIGHT = '26px';

type PropType = {
  openMainMenu: (e?: React.MouseEvent<HTMLElement>, menuItem?: MainMenuItemType) => void;
  open: boolean;
  activeSearchOverlay: boolean;
};

type StateType = {
  activeSearchOverlay: boolean;
};

export default class CompactHeader extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      activeSearchOverlay: props.activeSearchOverlay,
    };
  }

  hide = () =>
    this.setState({
      activeSearchOverlay: false,
    });

  onSearchClick = () => {
    this.setState({ activeSearchOverlay: true });
  };

  render() {
    const { openMainMenu } = this.props;
    return (
      <Base>
        <QuickMenu open={false} openMainMenu={openMainMenu} />
        <Home to="/">
          <Logotype style={{ height: LOGO_HEIGHT, width: '125px', fill: style.WHITE }} />
        </Home>
        <MenuIcons>
          <SearchIcon style={{ margin: '15px' }} onClick={this.onSearchClick} />
          <UserButton />
        </MenuIcons>
        {this.state.activeSearchOverlay && <SearchOverlay onHide={this.hide} />}
      </Base>
    );
  }
}

const Base = styled.div({
  position: 'fixed',
  zIndex: style.ZIndex.Overlay - 1,
  top: 0,
  height: '65px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(70,37,108,0.90)',
  color: style.primaryText,
  backdropFilter: 'blur(5px)',
});

const Home = styled(Link, {
  display: 'flex',
  color: 'inherit',
  height: '100%',
  marginBottom: style.small,
  alignItems: 'center',
  ':hover': {
    color: style.primaryText,
  },
});

const MenuIcons = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});
