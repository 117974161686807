import MainMenuItemType from './MainMenuItem.type';

export const ROOT_KEY = '/';

export type ItemKeyType = string;

export function itemKey(item: MainMenuItemType): ItemKeyType {
  return item.url;
}

export function findItem(items: MainMenuItemType[], key: ItemKeyType): MainMenuItemType {
  for (const item of items) {
    if (itemKey(item) === key) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const foundItem = findItem(item.children, key);

      if (foundItem) {
        return foundItem;
      }
    }
  }
}

export function findParentKey(items: MainMenuItemType | MainMenuItemType[], visibleKey: ItemKeyType): ItemKeyType {
  if (Array.isArray(items)) {
    for (const item of items) {
      const currentKey = itemKey(item);
      if (currentKey === visibleKey) {
        return ROOT_KEY;
      }

      const parentKey = findParentKey(item, visibleKey);
      if (parentKey) {
        return parentKey;
      }
    }
  } else {
    if (items.children && items.children.length > 0) {
      for (const child of items.children) {
        if (itemKey(child) === visibleKey) {
          return itemKey(items);
        }

        const parentKey = findParentKey(child, visibleKey);
        if (parentKey) {
          return parentKey;
        }
      }
    }
  }

  return ROOT_KEY;
}
