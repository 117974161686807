import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import * as style from '../Style';

const iconStyled = styled({
  display: 'inline-flex',
  width: '1em',
  height: '1em',
  fontSize: style.pixelsToUnit(style.baseLineHeight, style.Unit.Em),
});

export default function factory(Glyph: typeof SVGComponent) {
  return iconStyled(({ compose, ...restProps }: StyledProps & React.HTMLAttributes<HTMLSpanElement>) => (
    <styled.Span {...restProps} css={compose()}>
      <Glyph width="1em" height="1em" fill="currentColor" />
    </styled.Span>
  ));
}
