import constants from './constants';
const { BASE_FONT_SIZE } = constants;

// Don't export this file from `./index.ts

// These are exported as string by `./values.ts`
export const thin = 1;
export const thick = 5;
export const tiny = Math.ceil(BASE_FONT_SIZE * 0.25);
export const small = Math.ceil(BASE_FONT_SIZE * 0.5);
export const medium = BASE_FONT_SIZE * 1;
export const large = BASE_FONT_SIZE * 2;
export const huge = BASE_FONT_SIZE * 3;
export const gigantic = BASE_FONT_SIZE * 4;
